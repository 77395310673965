const UK = [
    {
        "dashboard": "Dashboard",
        "connected": "connected",
        "bandWidth": "Bandwidth",
        "trafficClassification": "Traffic classification",
        "security":"Security",
        "operators": "Operators",
        "technology": "Technologies",
        "language": "en",
        "home": "Home",
        "dayMode": "Day mode",
        "deconnection": "Sign Out",
        "devices": "Devices",
        "notification": "Notifications",
        "reporting": "Reporting",
        "building": "Building",
        "internetAccess": "Internet access",
        "bills": "Bills",
        "settings": "Settings",
        "list": "List",
        "map": "Map",
        "reseller": "Reseller",
        "siteName": "Site name",
        "serialnum": "Serial number",
        "state": "State",
        "nbOfActivePorts": "Nb active ports",
        "model": "Model",
        "template": "Template",
        "search": "Search",
        "address": "Address",
        "addTemplate": "Add a template",
        "disk": "Disk",
        "details": "Details",
        "deviceCharges": "Device charge",
        "ipGroup": "IP Group",
        "Group": "Group",
        "addIpAdress": "Add IP adress",
        "addIpGroup": "Add an Ip group",
        "portGroup": "Ports group",
        "networkorIpAdress": "Network or IP adress",
        "addPorts": "Add a port",
        "debit": "Debit",
        "ipAdress": "Ip adress",
        "relay": "Relay",
        "filtering": "Filtering",
        "addFiltering": "Ajouter filtrage",
        "interface": "Port",
        "AddVirtualInterface": "Add a virtual port",
        "gateway": "Gateway",
        "addGateway": "Add a gateway",
        "name": "Name",
        "ipAdressTarget": "Ip adress target",
        "latency": "Latency",
        "lossPacket": "Packet loss",
        "firewall": "Firewall",
        "entry": "Entry",
        "name_adress": "Name / IP adress",
        "AddDomain": "Add a domain",
        "ipStart": "Ip start",
        "ipEnd": "Ip end",
        "leaseTime": "Lease time",
        "domainName": "Domain name",
        "staticIp": "Static IP",
        "macAdress": "Mac adress / ip adress",
        "addIpStatic": "Add an static ip",
        "optionID": "id option / value",
        "addOption": "Add an option",
        "ipRelay": "IP relay",
        "output": "Output",
        "protocol": "protocol",
        "IpSourceAdressPorts": "Ip source adress / Ports",
        "accept": "Accept",
        "reject": "Reject",
        "block": "Block",
        "dscpModifications": "DSCP modifications",
        "Modifications_8021P": "802.1P modifications",
        "rules": "Rules",
        "comment": "comment",
        "addRule": "Add a rule",
        "userslist": "Users list",
        "categoriesAllowed": "Allowed categories",
        "whiteList": "White list",
        "blackList": "Black list",
        "addUrl": "Add an Url",
        "color": "Color",
        "reservation": "Reservation",
        "destinationIp": "Destination Ip",
        "SourceIp": "Source Ip",
        "AddCos": "Add a COS",
        "routing": "Routing",
        "ipSourceAddress": "Source IP adress",
        "destination": "Destination",
        "selfgoverning": "Self-governing",
        "addRoutage": "Add a routage",
        "confirm": "Confirm",
        "cancel": "cancel",
        "createIpsec": "Create an Ipsec",
        "updateIpsec": "Update an Ipsec",
        "disconnected": "disconnected",
        "inProgress": "In progress",
        "all": "All",
        "history": "History",
        "jitter": "Jitter",
        "classOfService": "Class of services",
        "consumption4g": "4G Consumption",
        "previous": "Previous",
        "next": "Next",
        "traffic": "Traffic",
        "column": "Column",
        "remoteIpAdress": "Remote Ip Adress",
        "localId": "Remote id",
        "localIpAdress": "Local Ip Adress",
        "localId": "Local id",
        "encryptionAlgorithm": "Encryption algorithm",
        "hashAlgorithm": "Hash algorithm",
        "dhGroup": "DH Group",
        "localNetwork": "Local network",
        "addLocalNetwork": "Add local network",
        "remoteNetwork": "Remote network",
        "addRemoteNetwork": "Add remote network",
        "AddIpsec": "Add Ipsec",
        "reference": "Reference",
        "account": "Account",
        "users": "Users",
        "entity": "Entity",
        "firstname": "Firstname",
        "phone": "Phone",
        "role": "Role",
        "administrator": "Administrator",
        "superAdministrator": "Super administrator",
        "beginDate": "Begin date",
        "endDate": "End date",
        "noRecords": "No records",
        "categories": "Categories",
        "remoteVpn": "Remote VPN",
        "accessibleNetwork": "Accessible network",
        "addRemoteVpn": "Add a remote VPN",
        "results": "results",
        "filteredResults": "filtered results",
        "register": "Save",
        "boitierDescription": "SND : device installed on customer sites ...",
        "DashboardCustom": "Customize your dashboard",
        "degraded": "degraded",
        "addSite": "Add a site",
        "move": "move",
        "addTranslation": "Add a translation",
        "archive": "Archive",
        "PortConfirmation": "Do you want to change the port",
        "addGroup": "Add a group",
        "marketPlace": "Market place",
        "help":"Help",
        "day": "Day",
        "week": "Week",
        "month": "Month",
        "thesdwan": "The SD-WAN is",
        "operationnal": "operationnal to",
        "flatRate" : "Flat rate",
        "inventoryManagement": "Inventory management",
        "update": "Update",
        "road": "Road",
        "roadEntry": "Road entry",
        "roadOutput": "Road output",
        "portEntry": "Port entry",
        "portOutput": "Port output"

    }
]

export { UK };