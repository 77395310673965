import React, {Component} from 'react';
import {Button, Modal} from "antd";
import TextField from "../../../../common/TextField/TextField";
import Socket from "../../../../Utils/Socket/Socket";
import SelectSimple from "../../../../common/SelectSimple/SelectSimple";
import SelectMultiple from "../../../../common/SelectMultiple/SelectMultiple";
import './UserForm.scss';
import User from "../../../../Utils/User/User";
import {connect} from "react-redux";
import AvatarProfile from "../../../../assets/images/Layout/profil.png";


const userState = {
    _id: "",
    email: "",
    enabled: true,
    EntityUser: [],
    firstname: "",
    lastname: "",
    mobile: "",
    parent_id: "",
    password: "",
    profile: "",
    role_id: "",
};

const authorizationsState = {
    permission_wanup: [{
        AccesInternet: {read: false, write: false},
        Dashboard: {read: false, write: false},
        Equipements: {read: false, write: false},
        Labo: {read: false, write: false},
        Notifications: {read: false, write: false},
        Rapport: {read: false, write: false},
        Securite: {read: false, write: false},
    }], service: [{
        Athena: false, Sophia: false, Spirit: false, wanup: false, "R-fiber": false
    }],
}

class UserForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: userState, authorizations: authorizationsState
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.formData !== this.props.formData) {
            if (this.props.formData) {
                this.setState(state => ({...state, user: userState, authorizations: authorizationsState}));
                if (this.props.formData.type === 'update') this.setUser();
            }
        }
    }

    async setUser() {
        const user = this.props.formData.data;
        const authorizations = await this.getUserAuthorizations(user.role_id);
        this.setState(state => ({...state, user, authorizations}));
    }

    async getUserAuthorizations(role_id) {
        if (role_id) {
            const {data = [null]} = await Socket.read('Roles', {_id: role_id});
            return {
                permission_wanup: [{...authorizationsState.permission_wanup[0], ...data[0].permission_wanup[0]}],
                service: [{...authorizationsState.service[0], ...data[0].service[0]}]
            };
        } else {
            return authorizationsState;
        }
    }

    changeUser = (name, value) => {
        this.setState(state => ({...state, user: {...state.user, [name]: value}}));
    }

    selectUserProfile = (value) => {
        this.setState(state => ({...state, user: {...state.user, profile: value}}));
    }

    selectPlatformAuthorizations = (platformName, authorized) => {
        this.setState(state => ({
            ...state, authorizations: {
                ...state.authorizations, service: [{
                    ...state.authorizations.service[0], [platformName]: authorized
                }]
            }
        }))
    }

    userPlatformAuthorizations = () => {
        const {authorizations} = this.state;
        const {service} = authorizations;
        const isAuthorized = [];
        for (const [key, value] of Object.entries(service[0])) {
            if (value) isAuthorized.push(key);
        }
        return isAuthorized;
    }

    userPagesAuthorization = (type) => {
        const {authorizations} = this.state;
        const {permission_wanup} = authorizations;
        const isAuthorized = [];
        for (const [key, value] of Object.entries(permission_wanup[0])) {
            if (value[type]) isAuthorized.push(key);
        }
        return isAuthorized;
    }

    selectPagesAuthorizations = (page, type, authorized) => {
        if (type === 'read' && authorized === false) this.selectPagesAuthorizations(page, 'write', false);
        if (type === 'write' && authorized === true) this.selectPagesAuthorizations(page, 'read', true);
        this.setState(state => ({
            ...state, authorizations: {
                ...state.authorizations, permission_wanup: [{
                    ...state.authorizations.permission_wanup[0], [page]: {
                        ...state.authorizations.permission_wanup[0][page], [type]: authorized
                    }
                }]
            }
        }))
    }

    userParentID = async (userInfo) => {
        if( userInfo.EntityUser[0].entity_uuid === "b6c0811f-32ce-44be-beba-50398490d95d") {
            return null;
        } else if(this.props.subEntityID) {
            const resEntityUser = await Socket.read('EntityUser', {entity_uuid: this.props.subEntityID})
            let adminID = '';
            for(const {user_uuid} of resEntityUser.data) {
                const user = await Socket.read('User', {_id: user_uuid});
                if(user.data[0].profile === "Administrateur") {
                    adminID = user.data[0]._id;
                    break;
                }
            }
            return adminID;
            // const user = await Socket.read('User', {_id})
        } else {
            return userInfo._id;
        }
    }

    async userEmailExist(email) {
        const res = await Socket.read("User", {email});
        const isExist = res.data.length > 0
        if(isExist) {
            const inputMail = this.refForm.querySelector('input[type="email"]');
            const existEmailError = this.refForm.querySelector('.email__exist');
            existEmailError.style.display = 'flex';
            inputMail.style.border = '1px solid red'
        }
        return isExist;

    }

    checkEmptyFields = () => {
        const requiredFields = this.refForm.querySelectorAll('[data-required="true"]');
        let error = false;
        for(const field of requiredFields) {
            const valueField = field.getAttribute('value');
            if(!valueField) {
                const nameField = field.getAttribute('name');
                const empty = `.${nameField}__empty`
                const emptyError = this.refForm.querySelector(empty);
                field.classList.add('border-error');
                emptyError.style.display = 'flex';
                error = true;
            }
        }
        return error;
    }

    emailIsValid = (email) => {

        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        const isValid = regex.test(email);
        if(!isValid) {
            const emailInput = this.refForm.querySelector('[name="email"]');
            const emailInvalidMsg = this.refForm.querySelector('.email__invalid');
            // console.log(emailInput, emailInvalidMsg)
            emailInput.classList.add('border-error');
            emailInvalidMsg.style.display = 'flex!important';
        }
        return isValid;
    }

    removeErrors = () => {
           const selectors = [ ".mobile__invalid",
            ".mobile__empty",
            ".email__exist",
            ".email__invalid",
            ".lastname__empty",
            ".firstname__empty ",
            ".email__empty",
           ]

        for(const selector of selectors) {
            const element = this.refForm.querySelector(selector);
            element.style.display = 'none';
        }
        for(const element of this.refForm.querySelectorAll( "[data-required='true']")) {
            element.classList.remove('border-error');
        }
    }

    async createUser() {
        const {entityId, subEntityID} = this.props;
        const {user, authorizations} = this.state;
        this.removeErrors();

        // if(!this.emailIsValid(user.email)) return;
        if(this.checkEmptyFields()) return;
        if(await this.userEmailExist(user.email)) return;

        const role = await Socket.insert('Roles', null, authorizations);
        const userInfo = await User.userInfo();
        const newUser = {
            ...user,
            parent_id: await this.userParentID(userInfo),
            role_id: role.data._id
        }

        try {
            const resUser = await Socket.insert('User', null, newUser);
            const entityUser = {entity_uuid: subEntityID ? subEntityID : entityId, user_uuid: resUser.data._id};
            try {
                const resEntityUser = await Socket.insert("EntityUser", null, entityUser);
                this.props.closeForm();
            } catch (error) {
                console.error(error)
            }
        } catch (error) {
            console.error(error);
        }
    }

    async updateUser() {
        const {user, authorizations} = this.state;
        this.removeErrors();
        // if(!this.emailIsValid(user.email)) return;
        if(this.checkEmptyFields()) return;

        const userReq = Socket.update('User', user._id, null, user);
        const authorizationsReq = Socket.update('Roles', user.role_id, null, authorizations);
        const promises = [userReq, authorizationsReq];
        Promise.all(promises)
            .then(res => {
                this.props.closeForm();
            })
            .catch(error => {
                console.error(error)
            })
    }

    submit = () => {
        const {formData} = this.props;
        if (formData && formData.type === 'add') this.createUser();
        if (formData && formData.type === 'update') this.updateUser();
    }


    getAuthorizationsPlatform = () => {
        const user = this.props.userInfo;
        const platformAuthorizations = Object.keys(authorizationsState.service[0]);

        if(user && user.EntityUser && user.EntityUser[0] && user.EntityUser[0].entity_uuid === "b6c0811f-32ce-44be-beba-50398490d95d") {
            return platformAuthorizations;
        } else {
            return platformAuthorizations.filter(platform => platform === 'wanup');
        }
}
    render() {
        const {formVisible, cancelUserForm, imgValide} = this.props;
        const {user} = this.state;
        const image = user && imgValide === true ? `${process.env.REACT_APP_BACK_URL_IMG}user/${user && user._id + '.png'}?${global.Date.now()}` : AvatarProfile
        const platformList = this.getAuthorizationsPlatform();
        const pagesList = Object.keys(authorizationsState.permission_wanup[0]);
        return (
            <Modal visible={formVisible} onCancel={cancelUserForm}
                   title={this.props.formData?.type === "add" ? 'Ajouter un utilisateur' : "Modifier l'utilisateur"}
                   footer={[
                <Button key="back" className={'button-confirm'} onClick={this.submit}>
                    Confirmer
                </Button>,
                <Button key="backy" className={'button-cancel'} onClick={this.props.cancelUserForm}>
                    Annuler
                </Button>
            ]}>
                <div className={'UserForm'} ref={ref => this.refForm = ref}>
                    <figure>
                        <img src={image}/>
                    </figure>
                    <div>
                        <h4>Informations utilisateur</h4>
                        <TextField
                            type={'text'}
                            value={user.firstname}
                            name={"firstname"}
                            placeholder={'Prénom'}
                            changeValue={this.changeUser}
                            required={true}
                        />
                        <div className={'ctn__error'}>
                        <span className={'firstname__empty'}>Veuillez entrer un prénom.</span>
                        </div>
                            <TextField
                            type={'text'}
                            value={user.lastname}
                            name={"lastname"}
                            placeholder={'Nom'}
                            changeValue={this.changeUser}
                            required={true}
                        />

                        <div className={'ctn__error'}>
                        <span className={'lastname__empty'}>Veuillez entrer un nom.</span>
                        </div>
                            <TextField
                            type={'email'}
                            value={user.email}
                            name={"email"}
                            placeholder={'e-mail'}
                            changeValue={this.changeUser}
                            required={true}
                        />

                        <div className={'ctn__error'}>
                        <span className={'email__exist'}>Cet e-mail est déjà associé à un compte.</span>
                        <span className={'email__invalid'}>Veuillez entrer un e-mail valide.</span>
                        <span className={'email__empty'}>Veuillez entrer un e-mail.</span>
                        </div>
                            <TextField
                            type={'tel'}
                            value={user.mobile}
                            name={"mobile"}
                            placeholder={'Numéro de téléphone'}
                            changeValue={this.changeUser}
                            // required={true}
                        />
                        <div className={'ctn__error'}>
                        <span className={'mobile__empty'}>Veuillez entrer un numéro de téléphone.</span>
                        <span className={'mobile__invalid'}>Veuillez entrer un numéro valide.</span>
                        </div>
                            <SelectSimple
                            items={['Développeur', 'Technicien', 'Administrateur']}
                            value={user.profile}
                            placeholder={'Sélectionnez un profile'}
                            changeValue={this.selectUserProfile}
                            className={"user__form__select"}
                        />
                    </div>
                    <div>
                        <h4>Autorisations</h4>
                        <SelectMultiple
                            items={platformList}
                            Select={(value) => this.selectPlatformAuthorizations(value, true)}
                            Deselect={(value) => this.selectPlatformAuthorizations(value, false)}
                            value={this.userPlatformAuthorizations()}
                            className={"user__form__select"}
                        />
                    </div>
                    <div>
                        <h4>Accès Wanup</h4>
                        <div className={'ctn__wanup__access'}>
                            <div>
                                <label htmlFor="">Lecture</label>
                                <SelectMultiple
                                    items={pagesList}
                                    Select={(value) => this.selectPagesAuthorizations(value, 'read', true)}
                                    Deselect={(value) => this.selectPagesAuthorizations(value, 'read', false)}
                                    value={this.userPagesAuthorization('read')}
                                    className={"user__form__select"}
                                />
                            </div>
                            <div>
                                <label htmlFor="">Ecriture</label>
                                <SelectMultiple
                                    items={pagesList}
                                    Select={(value) => this.selectPagesAuthorizations(value, 'write', true)}
                                    Deselect={(value) => this.selectPagesAuthorizations(value, 'write', false)}
                                    value={this.userPagesAuthorization('write')}
                                    className={"user__form__select"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>);
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.NavbarReducer.user,
        entityId: state.NavbarReducer.entity_id,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToprops)(UserForm);
