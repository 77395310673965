import React from 'react';
import { Select } from 'antd';
const { Option } = Select;


class SelectSimple extends React.Component {
    onChange = (value) => {
        const { name } = this.props;
        if (name) {
            this.props.changeValue(name, value);
        } else {
            this.props.changeValue(value);
        }
    };

    render() {
        const { items, className, value, placeholder, valuesName, valuesDisplay, disabled, dropdownName, required, ariaLabel, inputProps } = this.props;
        return (
            <Select
                filterSort={(optionA, optionB) => {
                    if (optionA && optionA.children && optionB && optionB.children) {
                        return optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                    }
                }}
                value={value}
                // defaultValue={'Admin'}
                placeholder={placeholder}
                onSelect={this.onChange}
                aria-label={ariaLabel}
                inputprops={inputProps}
                className="col-12 mb-2"
            >
                {items.map((item, index) => {
                    if (valuesName && valuesDisplay) {
                        return (
                            <Option key={index} value={item[valuesName]}>
                                {item[valuesDisplay]}
                            </Option>
                        );
                    } else {
                        return (
                            <Option key={index} value={item}>
                                {item}
                            </Option>
                        );
                    }
                })}
            </Select>
        );
    }
}

export default SelectSimple;
