const initialState = {
    visibleModalIpsec: "",
    modalType: "",
    newIpsec: [],
    myApp: [],
    cos: [],
    modelData: [],
    visibleModalModele: ""
}

const BuildingReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case "VISIBLE_MODAL_IPSEC":
            newState = {
                ...state,
                visibleModalIpsec: action.value
            }
            return newState;
        case "VISIBLE_MODAL_NOMADE":
            newState = {
                ...state,
                visibleModalNomade: action.value
            }
            return newState;
        case "VISIBLE_MODAL_MODELE":
            newState = {
                ...state,
                visibleModalModele: action.value
            }
            return newState;
        case "VISIBLE_MODAL_COS":
            newState = {
                ...state,
                visibleModalCos: action.value
            }
            return newState;
        case "VISIBLE_MODAL_INTERCO":
            newState = {
                ...state,
                visibleModalInterco: action.value
            }
            return newState;
        case "MODAL_TYPE":
            newState = {
                ...state,
                modalType: action.value
            }
            return newState;
        case "MODAL_COS_TYPE":
            newState = {
                ...state,
                modalTypeCos: action.value
            }
            return newState;
        case "NEW_IPSEC":
            newState = {
                ...state,
                newIpsec: action.value
            }
            return newState;
        case "IPSEC_DATA":
            newState = {
                ...state,
                ipsecData: action.value
            }
            return newState;
        case "NOMADE_DATA":
            newState = {
                ...state,
                nomadeData: action.value
            }
            return newState;
        case "COS_DATA":
            newState = {
                ...state,
                cosData: action.value
            }
            return newState;
        case "MODEL_DATA":
            newState = {
                ...state,
                modelData: action.value
            }
            return newState;
        case "SET_APP":
            newState = {
                ...state,
                myApp: action.value
            }
            return newState;
        case "SET_COS":
            newState = {
                ...state,
                cos: action.value
            }
            return newState;
        case "SET_RESERVATION":
            newState = {
                ...state,
                reservation: action.value
            }
            return newState;
        case "SET_LIMITATION":
            newState = {
                ...state,
                limitation: action.value
            }
            return newState;
        default:
            return state;
    }
}

export default BuildingReducer;