import React from 'react';
import { Select } from 'antd';
import './SelectMultiple.scss';

const { Option } = Select;

class SelectSimple extends React.Component {
    Select = (field) => {
        this.props.Select(field)
    };

    Deselect = (field) => {
        this.props.Deselect(field)
    };

    render() {
        const { items, valuesName, valuesDisplay, placeholder, value, ariaLabel, inputProps, className } = this.props;
        return (
            <Select
                filterSort={(optionA, optionB) => {
                    // if (optionA && optionA.children && optionB && optionB.children) {
                    //     return optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                    // }
                }}
                // defaultOpen={true}
                mode="multiple"
                allowClear
                className={className ? className : "col-5 mb-2"}
                placeholder={placeholder}
                value={value}
                defaultValue={value}
                onSelect={this.Select}
                onDeselect={this.Deselect}
                aria-label={ariaLabel}
                inputprops={inputProps}
            >
                {items.map((item) => {
                    if (valuesName && valuesDisplay) {
                        return (
                            <Option value={item[valuesName]}>
                                {item[valuesDisplay]}
                            </Option>
                        );
                    } else {
                        return (
                            <Option value={item}>
                                {item}
                            </Option>
                        );
                    }
                })}
            </Select>
        );
    }
}

export default SelectSimple;