import React, {Component} from 'react';
import error from "../../assets/images/404_NoData/404.svg";
import "./notfound.scss";


class notFound extends Component{

    componentDidMount() {
        document.title = "Page introuvable";
    }

    render(){
        return(
                <div id="notfound">
                    <img src={error}/>
                </div>
        );
    }
}

export default notFound;