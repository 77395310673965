import home from '../views/home/home';
import compte from '../components/compte/compte';
import gestionUtilisateurs from '../views/gestionUtilisateurs/gestionUtilisateurs';
import subClients from '../views/gestionUtilisateurs/gestionSubClients';
// import PasswordForm from '../common/passwordForm/PasswordForm';
import notFound from '../views/notFound/notfound';
import PasswordForm from "../common/PasswordForm/PasswordForm";





const routes = [
    { path: "/auth/home", component: home },
    { path: "/auth/compte", component: compte },
    { path: "/auth/gestion-utilisateurs", component: gestionUtilisateurs },
    { path: `/auth/clients/:id`, component: subClients },
    { path: `/auth/reset-password/`, component: PasswordForm },
    { path: `/auth/create-password/`, component: PasswordForm },
    {component: notFound}
]

export {routes};