
const initialState = {
    alert: "",
    filterAlert: "",
    notificationsSideBar : "",
    siteId : "",
    sideBarData: "",
    sites: "",
    equipments: "",
    displayInput: "",
    notifications: ""
}




const AlertReducer = (state = { initialState }, action) => {
    let newState;
        
    switch (action.type) {
        case "ALERT_FILTER":
            newState = {
                ...state,
                alert: action.value
            }
            return newState;
        case "FILTERED_ALERT":
            newState = {
                ...state,
                filterAlert: action.value
            }
            return newState;
        case "SITE_ID":
            newState = {
                ...state,
                siteId: action.value
            }
            return newState;
        case "NOTIFICATIONS_SIDEBAR":
            newState = {
                ...state,
                notificationsSideBar: action.value
            }
                return newState;
        case "SIDEBAR_DATA":
            newState = {
                ...state,
                sideBarData: action.value
            }
                return newState;        
        case "SITES_FILTER":
            newState = {
                ...state,
                sites: action.value
            }
                return newState;
        case "EQUIPMENTS_FILTER":
            newState = {
                ...state,
                equipments: action.value
            }
                return newState;  
        case "DISPLAY_INPUT":
            newState = {
                ...state,
                displayInput: action.value
            }
                return newState; 
        case "NOTIFICATIONS_COUNT":
            newState = {
                ...state,
                notifications: action.value
            }
                return newState;         
        default: {
            return state;
        }
    }
}

export default AlertReducer;