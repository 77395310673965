export const initialState = {
    countOperator: "",
    countTech: "",
    mapInit: "",
}

const DashboardReducer = (state = { initialState }, action) => {
    let newState;
    switch (action.type) {
        case "COUNT_OPERATOR":
            newState = {
                ...state,
                countOperator: action.value
            }
            return newState;
        case "COUNT_TECH":
            newState = {
                ...state,
                countTech: action.value
            }
            return newState;
        case "MAP":
            newState = {
                ...state,
                mapInit: action.value
            }
            return newState;
        case "SITE_ID":
            newState = {
                ...state,
                idSite: action.value
            }
            return newState;
        default:
            return state;

    }

}

export default DashboardReducer;