import React from "react";
import { UK } from "../../translation/uk_lang";
import { FR } from "../../translation/fr_lang";
import { connect } from 'react-redux';
import "./gestionUtilisateurs.scss";
import { Tabs } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from "react-router-dom";
// import SubClient from "../../components/gestionUtilisateurs/clients/subClients";
import SubClient from "../../components/gestionUtilisateurs/clients/subClients"
import Socket, { socket } from '../../Utils/Socket/Socket';
import UsersClient from "../../components/gestionUtilisateurs/users/usersClient";

const { TabPane } = Tabs;

const tabs = [
    { info: [{ name: "Gestion Utilisateurs", tab: "Gestion Utilisateurs" }], component: UsersClient },
    { info: [{ name: "Gestion Clients", tab: "Gestion Clients" }], component: SubClient },
]

class Security extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileTranslate: !localStorage.getItem("lang") ? FR : localStorage.getItem("lang") === "fr" ? FR : UK,
            theme: !localStorage.getItem("theme") ? true : JSON.parse(localStorage.getItem("theme")) === true ? true : false,
            entity: ''
        }
    }

    componentDidMount() {
        let url = window.location.pathname
        let id = url.substring(url.lastIndexOf('/') + 1);
        // console.log(id)
        Socket.read('Entity', { _id: id })
            .then(res => {
                this.setState({ entity: res.data[0] })
            })
            .catch(error => console.error(error))
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({ theme: this.props.theme === true ? true : false });
        }
        if (prevProps.translation !== this.props.translation) {
            this.setState({ fileTranslate: this.props.translation === "uk" ? UK : FR });
        }
    }

    // getEntity = async () => {
    //     Socket.read("Entity", { _id: this.props.entities })
    //         .then(res => {
    //         })
    //         .catch(error => console.error(error))
    // }



    render() {
        return (
            <div id="Users">
                <div className="ml-5">
                    <NavLink
                        to={{
                            pathname: "/auth/gestion-utilisateurs",
                            // aboutProps : elements
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowLeft}
                            //   onClick={this.context.router.history.goBack}
                            className="back-gestions-utilisateurs" style={{ color: "black" }} />
                    </NavLink>
                </div>
                <div className="users-content">
                    {this.state.fileTranslate.map(
                        (element, i) => (
                            <Tabs key={i}
                                defaultActiveKey="0"
                                className={!this.state.theme ? "tabs dark-slide" : "tabs"}
                            >
                                {
                                    this.state.entity &&
                                        this.state.entity.profile === "revendeur"
                                        ?
                                        tabs.map((tab, index) => {
                                            const Composent = tab.component;
                                            return (
                                                <TabPane tab={tab.info[0].name} key={index}>
                                                    <Composent
                                                        theme={this.state.theme}
                                                        translation={this.state.fileTranslate}
                                                    />
                                                </TabPane>
                                            )
                                        }) :
                                        <TabPane tab={tabs[0].info[0].name}>
                                            <UsersClient />
                                        </TabPane>
                                }
                            </Tabs>
                        )
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        translation: state.NavbarReducer.translation,
        theme: state.NavbarReducer.theme,
        buildingReducer: state.BuildingReducer,
        visibleModal: state.SecuriteReducer.visibleModalFireWall,
        entities: state.CollectionsReducer.Entity,
    };
}

const mapDispatchToProps = dispatch => {
    return {

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Security);