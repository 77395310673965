export const initialState = {
    live: false,
    dateStart: "",
    dateEnd: "",
    siteReporting: "",
    equipmentReporting: "",
    interfaceReporting: "",
    // idInterfaces: [],
    traffic_stats: null,
    // dataGraph: [],
    bandePassante: {},
    sla: {},
    status: {
        cpu: 0,
        ram: 0
    },
    alertsNotif: {
        information: 0,
        warning: 0,
        critical: 0,
    },
    liveBandePassante: {},
    liveSla: {}
}

const ReportingReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case "CHANGE_REPORTING_VALUE":
            return {
                ...state,
                [action.name]: action.value
            }
        case "CHANGE_REPORTING_MULTIPLE_VALUES":
            return {
                ...state,
                ...action.values
            }
        case "SUBMIT_REPORTING":
            const {dateStart, dateEnd, siteReporting, equipmentReporting, interfaceReporting} = action.value;
            return {
                ...state,
                live: false,
                dateStart: dateStart,
                dateEnd: dateEnd,
                siteReporting: siteReporting,
                equipmentReporting: equipmentReporting,
                interfaceReporting: interfaceReporting
            }
        default:
            return state;
    }
}

export default ReportingReducer;