const initialState = {
    collapse: true,
    menuCollapse: false,
    translation: "fr",
    theme: "light",
    entity_id: "",
    title: "",
    userInfos: "",
    update: "",
    TransitionWanup: false,
    TransitionSpirit: false,
    video: false,
}

const NavbarReducer = (state = { initialState }, action) => {
    let newState;
    switch (action.type) {

        case "COLLAPSE_NAVBAR":
            newState = {
                ...state,
                collapse: action.value
            }
            return newState;

        case "UPDATE":
            newState = {
                ...state,
                update: action.value
            }
            return newState;

        case "COLLAPSE_MENU":
            newState = {
                ...state,
                menuCollapse: action.value
            }
            return newState;

        case "TRANSLATION":
            newState = {
                ...state,
                translation: action.value
            }
            return newState;

        case "THEME":
            newState = {
                ...state,
                theme: action.value
            }
            return newState;

        case "ENTITY_ID":
            newState = {
                ...state,
                entity_id: action.value
            }
            return newState;

        case "VALUE_DEFAULT":
            newState = {
                ...state,
                valueDefault: action.value[0]
            }
            return newState;

        case "ENTITY_DATA":
            newState = {
                ...state,
                entity_data: action.value
            }
            return newState;

        case "TITLE_PAGE":
            newState = {
                ...state,
                title: action.value
            }
            return newState;

        case "USER_INFO":
            newState = {
                ...state,
                userInfos: action.value
            }
            return newState;

        case "TRANSITION_WANUP":
            newState = {
                ...state,
                TransitionWanup: action.value
            }
            return newState;

        case "TRANSITION_SPIRIT":
            newState = {
                ...state,
                TransitionSpirit: action.value
            }
            return newState;

        case "VIDEO":
            newState = {
                ...state,
                video: action.value
            }
            return newState;

        default:
            return state;
    }
}

export default NavbarReducer;