const initialState = {
    dataselect: "",
}

const SelectReducer = (state = { initialState }, action) => {
    let newState;
    switch (action.type) {
        case "SELECT_DATA":
            newState = {
                ...state,
                dataselect: action.value
            }
            return newState;
        default:
            return state;
    }
}

export default SelectReducer;