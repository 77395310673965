export const initialState = {
    visibleSidebar: false,
    validInterfaceVirtuel: 0
}

const EquipementReducer = (state = { initialState }, action) => {
    let newState;
    switch (action.type) {
        case "VISIBLE_SIDEBAR":
            newState = {
                ...state,
                visibleSidebar: action.value
            }
            return newState;
        case "ROW_INFO":
            newState = {
                ...state,
                rowInfo: action.value
            }
            return newState;
        case "API_MAPS":
            newState = {
                ...state,
                apiMaps: action.value
            }
            return newState;
        case "API_MAPS_COORD":
            newState = {
                ...state,
                apiMapsCoord: action.value
            }
            return newState;
        case "CHANGE_DATA_SIDEBAR":
            newState = {
                ...state,
                changeData: action.value
            }
            return newState;
        case "SELECT_NAME_BOITIER":
            newState = {
                ...state,
                selectNameBoitier: action.value
            }
            return newState;
        case "SELECT_VALUE_BOITIER":
            newState = {
                ...state,
                selectValueBoitier: action.value
            }
            return newState;
        case "SND_FILTER":
            newState = {
                ...state,
                sndFilter: action.value
            }
            return newState;
        case "INTERFACE_ACTIF":
            newState = {
                ...state,
                activeinterface: action.value
            }
            return newState;
        case "INTERFACE_OPEN":
            newState = {
                ...state,
                interfaceOpen: action.value
            }
            return newState;
        case "ROW_INFO_DHCP":
            newState = {
                ...state,
                rowInfoDhcp: action.value
            }
            return newState;
        case "MAC":
            newState = {
                ...state,
                mac: action.value
            }
            return newState;
        case "INTERFACE_ID":
            newState = {
                ...state,
                interfaceId: action.value
            }
            return newState;
        case "CHANGE_DETAIL_INTERFACE":
            newState = {
                ...state,
                changeDetailInterface: action.value
            }
            return newState;
        case "CHANGE_DETAIL_GATEWAYS":
            newState = {
                ...state,
                changeDetailGateways: action.value
            }
            return newState;
        case "CHECK_CHANGE_PORTS":
            newState = {
                ...state,
                checkChangePorts: action.value
            }
            return newState;
        case "DHCP_CHANGE":
            newState = {
                ...state,
                dhcpChange: action.value
            }
            return newState;
        case "RELAIS_CHANGE":
            newState = {
                ...state,
                relaisChange: action.value
            }
            return newState;
        case "GATEWAYS_CHANGE":
            newState = {
                ...state,
                gatewaysChange: action.value
            }
            return newState;
        case "ROUTAGE_CHANGE":
            newState = {
                ...state,
                routageChange: action.value
            }
            return newState;
        case "LINKS_CHANGE":
            newState = {
                ...state,
                linksChange: action.value
            }
            return newState;
        case "ALL_LINKS":
            newState = {
                ...state,
                allLinks: action.value
            }
            return newState;
        case "VALID_INTERFACE_VIRTUEL":
            newState = {
                ...state,
                validInterfaceVirtuel: action.value
            }
            return newState;
        default:
            return state;
    }
}

export default EquipementReducer;