import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import './ModalFormClient.scss';
// import AvatarProfile from '../../assets/d.png'
import Socket from '../../Utils/Socket/Socket';
import User from "../../Utils/User/User";
import { Select, Input, Button, Modal, Avatar, notification } from "antd";
import { userInfoType, Entities, Clients, Utils } from "../../store/types";
import SelectMultiple from "../../common/SelectMultiple/SelectMultiple";
import SelectSimple from "../../common/SelectSimple/SelectSimple";
import TextField from "../../common/TextField/TextField";


const { Option } = Select;

// const outils = ['Spirit', 'wanup', 'Athena', 'Sophia']
const outils = ['Spirit', 'wanup']

const mapStateToProps = (state: any) => {
    return {
        users: state.NavbarReducer.user,
        entities: state.CollectionsReducer.Entity,
        entityId: state.NavbarReducer.entity_id,
    };
};

const mapDispatchToprops = () => {
    return {
        // setTitle: (data) => dispatch(titlePage(data)),
    };
};

const connector = connect(mapStateToProps, mapDispatchToprops)


type PropsFromRedux = ConnectedProps<typeof connector> & RouteComponentProps<any>

interface Props extends PropsFromRedux {
    modal: any,
    modalSociete: Entities,
    actionModal: boolean,
    handleCancel: () => void;
    modalType: string
    page: Array<any>,
    roleUsers: Array<any>,
    services: Array<any>,
    parent_id: string
}

interface State {
    closeModal: boolean,
    arrayInfo: any,
    type: string,
    userInfo: userInfoType,
    entity: string,
    societeInfo: Entities,
    profileSociete: string,
    services: Array<any>,
    Writepages: Array<any>,
    Readpages: Array<any>,
    data: Array<any>
    roles: any,
    types: string,
    arrayServices: Array<any>
}

class modalFormClients extends React.PureComponent<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            types: '',
            closeModal: false,
            arrayInfo: [],
            data: [],
            services: [],
            type: '',
            Writepages: [],
            Readpages: [],
            // utils: this.props.modal ? this.props.page[0].service : { Spirit: false, wanup: false, Athena: false, Sophia: false },
            userInfo: {
                _id: "",
                firstname: "",
                email: "",
                role_id: "",
                profile: "",
            },
            entity: '',
            societeInfo: {
                _id: "",
                parent_id: "",
                name: "",
                profile: "",
                nsiret: ""
            },
            profileSociete: '',
            roles: {},
            arrayServices: []
        }
    }

    componentDidMount() {
        this.userInfo();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.Writepages !== this.state.Writepages) {
            this.setState({ Writepages: this.state.Writepages })
        }
        if (prevProps.page !== this.props.page) {
            if (this.props.page.length === 3) {
                this.setState({ Writepages: this.props.page, Readpages: this.props.page })
            } else {
                this.setState({ Writepages: this.props.page[0], Readpages: this.props.page[1] })
            }
        }
        if (prevProps.roleUsers !== this.props.roleUsers) {
            this.setState({ roles: this.props.roleUsers })
        }
        if (prevProps.modalSociete !== this.props.modalSociete) {
            this.setState({ societeInfo: this.props.modalSociete })
        }
        if (prevState.roles !== this.state.roles) {
            this.refactoData(this.state.roles)
        }
        if (prevProps.services !== this.props.services) {
            this.setState({ services: this.props.services })
        }
        if (prevProps.modal !== this.props.modal) {
            this.setState({ arrayInfo: this.props.modal });
        }
    }

    refactoData = (value: any) => {
        if (value) {
            const role = value
            const asArray: Array<any> = Object.entries(role);
            let data = []
            let data1 = []
            for (let i = 0; i < asArray.length; i++) {
                let value = asArray[i][0]
                let write = asArray[i][1].write
                let read = asArray[i][1].read
                if (write === true) data.push({ value })
                if (read === true) data1.push({ value })
            }
            this.setState({ Writepages: data, Readpages: data1 });
        }
    }

    userInfo = async () => {
        let user = await User.userInfo();
        let arrayServices: Array<any> = []

        await Socket.read('Roles', { _id: user.role_id })
            .then((res) => {
                if (res.data.length > 0 && res.data[0].service.length > 0) {
                    outils.forEach((outilsElem, i) => {
                        res.data[0].service.forEach((element: { [x: string]: any; }) => {
                            if (element[outilsElem]) arrayServices.push(outilsElem)
                        });
                    })
                }
            })

        this.setState({ userInfo: user, arrayServices });
    }

    handleChange = (name: string, value: string) => {
        this.setState((state) => ({ arrayInfo: { ...state.arrayInfo, [name]: value, role_id: '' } }))
    }

    onDeselect = (field: string) => {
        let arrUpdated: Array<any> = [...this.state.services]
        arrUpdated[0] = {
            ...arrUpdated[0],
            [field]: false
        }
        this.setState({ services: arrUpdated })
    }

    onSelect = (field: string) => {
        let arrUpdated: Array<any> = [...this.state.services]
        arrUpdated[0] = {
            ...arrUpdated[0],
            [field]: true
        }
        this.setState({ services: arrUpdated })
    }

    onChangeSociete = (name: string, value: string) => {
        this.setState((state) => ({ societeInfo: { ...state.societeInfo, [name]: value, parent_id: this.props.parent_id } }))
    }

    onSelectWrite = (field: string) => {
        this.setState((state) => ({
            roles: { ...state.roles, [field]: { read: true, write: true } },
        }));
    }

    onDeselectWrite = (field: string) => {
        if (this.state.roles[field] === undefined) {
            this.setState((state) => ({
                roles: { ...state.roles, [field]: { read: true, write: false } },
            }));
        } else {
            this.setState((state) => ({
                roles: { ...state.roles, [field]: { read: this.state.roles[field].read, write: false } },
            }));
        }
    }

    onSelectRead = (field: string | any) => {
        if (this.state.roles[field] === undefined) {
            this.setState((state) => ({
                roles: { ...state.roles, [field]: { read: true, write: false } },
            }));
        } else {
            this.setState((state) => ({
                roles: { ...state.roles, [field]: { read: true, write: this.state.roles[field].write } },
            }));
        }
    }

    onDeselectRead = (field: string | any) => {
        if (this.state.roles[field] === undefined) {
            this.setState((state) => ({
                roles: { ...state.roles, [field]: { read: false, write: false } },
            }));
        } else {
            if (this.state.roles[field].write === true) {
                this.setState((state) => ({
                    roles: { ...state.roles, [field]: { read: true, write: this.state.roles[field].write } },
                }));
            } else {
                this.setState((state) => ({
                    roles: { ...state.roles, [field]: { read: false, write: this.state.roles[field].write } },
                }));
            }
        }
    }

    createClient = () => {
        const { arrayInfo, societeInfo, services, roles } = this.state
        const { firstname, lastname, email, mobile } = arrayInfo
        // const { name, profile, nsiret, parent_id } = societeInfo
        const dataRights = { permission_wanup: [roles], service: services }

        Socket.read("User", { email: email })
            .then(res => {
                if (res.data.length !== 0) {
                    this.props.handleCancel()
                    setTimeout(() => {
                        this.createNotificationWarning()
                    }, 500);
                } else {
                    Socket.insert("Roles", null, dataRights)
                        .then(res => {
                            const userInfoUpdated = { firstname, lastname, email, mobile, enabled: true, role_id: res.data._id }
                            Socket.insert("User", null, userInfoUpdated)
                                .then(res => {
                                    const user_uuid = res.data._id;
                                    const societeInfoUpdated = { ...societeInfo, enabled: true }
                                    Socket.insert("Entity", null, societeInfoUpdated)
                                        .then(res => {
                                            const entityUsers = { entity_uuid: res.data._id, user_uuid: user_uuid }
                                            Socket.insert("EntityUser", null, entityUsers).then(res => {
                                                if (res.status === 200) {
                                                    this.props.handleCancel()
                                                    setTimeout(() => {
                                                        this.createNotificationSuccess()
                                                    }, 500);
                                                } else {
                                                    this.props.handleCancel()
                                                    setTimeout(() => {
                                                        this.createNotificationError()
                                                    }, 500)
                                                }
                                            })
                                        })
                                })
                        })
                }
            })
    }

    updateClient = async () => {
        const { arrayInfo, societeInfo, types, roles, services } = this.state
        const { firstname, lastname, email, mobile } = arrayInfo
        const { name, profile, nsiret } = societeInfo
        const users = { firstname, lastname, email, mobile, profile: types }
        const dataRights = { permission_wanup: [roles], service: services };

        Socket.update("User", arrayInfo._id, null, users)
            .then(res => {
                Socket.update("Roles", arrayInfo.role_id, null, dataRights)
                    .then(res => {
                        const infoSocieteUpdated = { name, profile, nsiret }
                        Socket.update("Entity", societeInfo._id, null, infoSocieteUpdated)
                            .then(res => {
                                if (res.status === 200) {
                                    this.props.handleCancel()
                                    setTimeout(() => {
                                        this.updateNotificationSuccess()
                                    }, 500);
                                } else {
                                    this.props.handleCancel()
                                    setTimeout(() => {
                                        this.updateNotificationError()
                                    }, 500);
                                }
                            })
                            .catch(error => console.error(error))
                    })
                    .catch(error => console.error(error))
            })
            .catch(error => console.error(error))
    }

    usersAction = () => {
        const { modalType } = this.props;
        (modalType === "Nouveau client") ? this.createClient() : this.updateClient()
    }

    updateNotificationSuccess = () => {
        notification.success({
            duration: 5,
            message: 'Mise à jour effectuée',
            placement: "top"
        });
    };
    updateNotificationError = () => {
        notification.error({
            duration: 5,
            message: 'Mise à jour échouée',
            placement: "top"
        });
    };
    createNotificationSuccess = () => {
        notification.success({
            duration: 5,
            message: 'Création effectuée',
            placement: "top"
        });
    };
    createNotificationError = () => {
        notification.error({
            duration: 5,
            message: 'Création échouée',
            placement: "top"
        });
    };
    createNotificationWarning = () => {
        notification.warning({
            duration: 5,
            message: 'Email déjà existant',
            placement: "top"
        });
    };

    render() {
        const { modal, actionModal, modalType } = this.props;
        const { societeInfo, arrayServices } = this.state;
        const options = [{ value: 'Dashboard' }, { value: 'Equipements' }, { value: 'Notifications' }, { value: 'Rapport' }, { value: 'Labo' }, { value: 'Securite' }, { value: 'AccesInternet' }]
        let arrayValueServices: Array<any> = []

        this.state.services.length > 0 && outils.forEach((elem, i) => {
            if (this.state.services[0] && this.state.services[0][elem]) return arrayValueServices.push(elem)
        })

        return (
            <Modal
                // title={modalType}
                visible={actionModal}
                destroyOnClose={true}
                maskClosable={false}
                onCancel={this.props.handleCancel}
                footer={[
                    <Button key="back" onClick={this.usersAction} className="button-confirm">
                        {modalType === "Nouveau client" ? <>Créer</> : <>Modifier</>}
                    </Button>,
                    <Button key="back" onClick={this.props.handleCancel} className="button-cancel">
                        Annuler
                    </Button>
                ]}
            >
                <div>
                    <p>Société</p>
                    <TextField
                        placeholder="Entrer le nom de la société"
                        value={societeInfo.name}
                        name={'name'}
                        changeValue={this.onChangeSociete}
                        type={'text'}
                        ariaLabel={`societe`}
                        inputProps={{ "data-testid": "content-input" }}
                        id="content"
                    />
                    <TextField
                        placeholder="Entrer le n° Siret"
                        value={societeInfo.nsiret}
                        name={'nsiret'}
                        changeValue={this.onChangeSociete}
                        type={'text'}
                        ariaLabel={`siret`}
                        inputProps={{ "data-testid": "content-input" }}
                        id="content"
                    />
                    <SelectSimple
                        className="col-12 mb-2"
                        items={['Revendeur', 'Client']}
                        value={societeInfo.profile}
                        placeholder={'Sélectionnez un profil'}
                        changeValue={this.onChangeSociete}
                        inputProps={{ "data-testid": "content-input" }}
                        ariaLabel={`profile`}
                        name={'profile'}
                    />
                </div>
                <div className="mt-3">
                    <p>Informations personnelles</p>
                    <TextField
                        placeholder="Prénom"
                        value={modal && modal?.firstname}
                        name={'firstname'}
                        changeValue={this.handleChange}
                        type={'text'}
                        ariaLabel={`firstname`}
                        inputProps={{ "data-testid": "content-input" }}
                        id="content"
                    />
                    <TextField
                        placeholder="Nom"
                        value={modal && modal?.lastname}
                        name={'lastname'}
                        changeValue={this.handleChange}
                        type={'text'}
                        ariaLabel={`lastname`}
                        inputProps={{ "data-testid": "content-input" }}
                        id="content"
                    />
                    <TextField
                        placeholder="Adresse mail"
                        value={modal && modal?.email}
                        name={'email'}
                        changeValue={this.handleChange}
                        type={'email'}
                        ariaLabel={`email`}
                        inputProps={{ "data-testid": "content-input" }}
                        id="content"
                    />
                    <TextField
                        placeholder="Téléphone"
                        value={modal && modal?.mobile}
                        name={'mobile'}
                        changeValue={this.handleChange}
                        type={'mobile'}
                        ariaLabel={`telephone`}
                        inputProps={{ "data-testid": "content-input" }}
                        id="content"
                    />
                </div>

                <div className="mt-3">
                    <p>Autorisations</p>
                    <SelectMultiple
                        className="col-12 mb-2"
                        mode="multiple"
                        items={arrayServices}
                        // valuesName={'name'}
                        // valuesDisplay={'name'}
                        value={arrayValueServices}
                        placeholder="Outils"
                        Deselect={(field: string) => this.onDeselect(field)}
                        Select={(field: string) => this.onSelect(field)}
                        inputProps={{ "data-testid": "content-input" }}
                        id="content"
                        ariaLabel={`autorisation`}
                    />
                </div>

                {
                    arrayValueServices.includes("wanup") &&

                    <div className="mt-3">
                        <p>Accès Wanup</p>
                        <div className={'select-roles'}>
                            <>
                                <SelectMultiple
                                    items={options ? options : []}
                                    valuesName={'value'}
                                    valuesDisplay={'value'}
                                    placeholder={'Lecture'}
                                    value={this.state.Readpages && this.state.Readpages.map(e => e.value)}
                                    Deselect={(field: string) => this.onDeselectRead(field)}
                                    Select={(field: string) => this.onSelectRead(field)}
                                    inputProps={{ "data-testid": "content-input" }}
                                    id="content"
                                    ariaLabel={`lecture`}
                                />
                                <SelectMultiple
                                    items={options ? options : []}
                                    valuesName={'value'}
                                    valuesDisplay={'value'}
                                    placeholder={'Écriture'}
                                    value={this.state.Writepages && this.state.Writepages.map(e => e.value)}
                                    Deselect={(field: string) => this.onDeselectWrite(field)}
                                    Select={(field: string) => this.onSelectWrite(field)}
                                    inputProps={{ "data-testid": "content-input" }}
                                    id="content"
                                    ariaLabel={`ecriture`}
                                />
                            </>
                        </div>
                    </div>
                }
            </Modal>
        )
    }
}

export default connector(modalFormClients);
