import { UPDATE_COLLECTIONS, UPDATE_ENTITY, UPDATE_COLLECTIONS_V2 } from "./types";
import { collectionsState } from "./state";

const initialState = collectionsState;

export default function collectionsReducer(state = initialState, action) {
    
    switch (action.type) {
        case UPDATE_COLLECTIONS:
            return {
                ...state,
                [action.collection]: action.value
            }
        case UPDATE_COLLECTIONS_V2:
            return {
                ...state,
                ...action.value
            }
        case UPDATE_ENTITY:
            let newEntity = [...state.Entity];
            let index = newEntity.indexOf(
                newEntity.find(n => n._id === action.value._id)
            )
            newEntity[index] = action.value;
            
            return {...state, Entity: newEntity};
        default:
            return state;
    }
}