import React from "react";
import { connect } from "react-redux";
import './gestion.scss';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Button, Avatar } from "antd";
import AvatarProfile from "../../../../assets/images/Layout/profil.png";
import { data_entity } from "../../../../store/action/navBarAction";


const { Panel } = Collapse;

class GestionSubclient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            allEntity: [],
            client: [],
            subClient: [],
            modalClients: '',
            entity: '',
        }
    }

    handleCancel = () => {
        this.setState({ isModalVisible: false });
    }

    handleOk = () => {
        this.setState({ isModalVisible: false });
    }

    subClient = (id) => {
        window.location.replace(`/auth/clients/${id}`)
    }

    header = (elements, i) => {
        const { users, entity, actionModal } = this.props
        return (
            <div className="headerPanel" key={i}>
                <div>
                    <Avatar className='Avatar' size="large" >
                        {elements.name.charAt(0).toUpperCase()}
                    </Avatar>
                </div>
                <p className="ml-3" style={{ fontWeight: "bold" }}>{elements.name.toUpperCase()}</p>
                <div style={{ position: "absolute", translate: "18vw" }}>
                    <Link
                        to={{
                            pathname: `/auth/clients/${elements._id}`,
                            query: { elements },
                        }}
                    >
                        <Button className="ml-2 mr-2" aria-label={`subclient${i}`}><FontAwesomeIcon icon={faUserFriends} /></Button>
                    </Link>
                    <Button className="ml-2 mr-2" aria-label={`editer${i}`} onClick={(event) => actionModal(event, { modalType: "modifications clients", modal: elements })} style={{ color: "#417bf6", borderColor: "#417bf6" }}>Éditer</Button>
                    <Button className="ml-2 mr-2" style={{ color: "red", borderColor: "red", marginBottom: "4vh" }} >Supprimer</Button>
                </div>
            </div>
        )
    }

    contain = (elements, i) => {
        const { users, entity, actionModal } = this.props
        return (
            <>
                {
                    users && users.map(element =>
                        this.state.client[i].user_uuid === element.parent_id &&
                        <div className="headerPanel">
                            <div className="list-subClient" key={i}>
                                <img src={AvatarProfile} alt="photo profile" />
                                <p className="ml-3" style={{ fontWeight: "bold" }}>{element.name.toUpperCase()}</p>
                                <p className="ml-3" style={{ fontWeight: "bold" }}>{element.name.toUpperCase()}</p>
                            </div>
                            <div className="ml-5">
                                <Link
                                    to={{
                                        pathname: `/auth/clients/${element._id}`,
                                        query: { elements }
                                    }}
                                >
                                    <Button style={{ color: "#7e7e7e" }}><FontAwesomeIcon icon={faUserFriends} /></Button>
                                </Link>
                                <Button className="ml-2 mr-2" onClick={(event) => actionModal(event, { modalType: "modifications clients", modal: element })} style={{ color: "#417bf6", borderColor: "#417bf6" }}><FontAwesomeIcon icon={faEdit} /></Button>
                                <Button style={{ color: "red", borderColor: "red" }}>supprimer</Button>
                            </div>
                        </div>
                    )
                }
            </>
        )
    }

    render() {
        const { users, entity, actionModal } = this.props
        return (
            <div className="users-list-container">
                <Collapse ghost>
                    {entity &&
                        entity.map((elements, i) => {
                            return (
                                <Panel
                                    key={i}
                                    header={this.header(elements, i)}
                                >
                                    {this.contain(elements, i)}
                                </Panel>
                            )
                        })
                    }
                </Collapse>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userInfos: state.NavbarReducer.userInfos,
        entityId: state.NavbarReducer.entity_id,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        data_entity: (data) => dispatch(data_entity(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(GestionSubclient);
