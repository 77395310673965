export function udpateCollections(collection, value) {   
    return {
        type: 'UPDATE_COLLECTIONS',
        collection: collection,
        value: value
    }
}

export function udpateCollectionsV2(value) {
    return {
        type: 'UPDATE_COLLECTIONS_V2',
        // collection: collection,
        value: value
    }
}


export function updateEntity(value) {
    return {
        type: 'UPDATE_ENTITY',
        value
    }
}