import React from 'react';
import './App.scss';
import { Route, Router, Switch } from 'react-router';
import { createBrowserHistory } from 'history/';
import Layout from './components/layout/layout';
import User from './Utils/User/User';
import Login from './views/Login/Login';
import { routes } from './config/routesConfig';
import { connect } from 'react-redux';
import './vars.scss';
import PasswordForm from "./common/PasswordForm/PasswordForm";
import IdleTimer from 'react-idle-timer';
import MobileError from './assets/errorMobile.jpg';


const browserHistory = createBrowserHistory();

interface State {
    count: any
    hideNav: any
}
class App extends React.Component<any, State> {
    private idleTimer: null;

  constructor(props: any) {
    super(props);
    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.state = {
      count: 0,
      hideNav: null
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
      this.setState({hideNav: window.innerWidth});
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.resize.bind(this));
  }

   render() {
    return (
      <div className="App">
        {this.state.hideNav < 900 ?
          <>
            <img src={MobileError} width="100%" height="100%" />
          </>
          :
            <>
            <IdleTimer
              ref={(ref: any) => { this.idleTimer = ref }}
              timeout={900000} //900000 = 15min
              onIdle={this.handleOnIdle}
              debounce={250}
            />
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/auth" component={Login} />
              <Route exact path="/auth/reset-password/:token" component={PasswordForm} />
              <Route exact path="/auth/create-password/:token/:email" component={PasswordForm} />
              <Layout>
                <Switch>
                  {routes.map((element, i) =>
                    <Route exact path={element.path} component={element.component} key={i} />
                  )}
                </Switch>
              </Layout>
            </Switch>
            </>
        }
      </div>
    );
  }
  handleOnIdle(event: any) {
    User.removeToken()
    // @ts-ignore
      window.location.replace(process.env.REACT_APP_BACK_URL)
  }
}

const mapStateToProps = (state: any) => {
  return {
      users: state.NavbarReducer.user,
      wanup: state.NavbarReducer.TransitionWanup,
      entities: state.CollectionsReducer.Entity,
      entityId: state.NavbarReducer.entity_id,
  };
};

const mapDispatchToprops = (dispatch: any) => {
  return {
      // setTitle: (data) => dispatch(titlePage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToprops)(App);
