import React from "react";
import { connect } from "react-redux";
import '../gestionUtilisateurs.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Input, Collapse } from "antd";
import ModalFormClient from "../../../common/ModalFormClient/ModalFormClient";
import Socket from "../../../Utils/Socket/Socket";
import { data_entity } from "../../../store/action/navBarAction";
import GestionSubClients from './gestion/gestionsSubClients'



const { Panel } = Collapse;

class SubClients extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            allEntity: [],
            client: [],
            subClient: [],
            modalClients: '',
            modalSociete: {},
            test: [],
            entity: '',
            id: '',
            modal: '',
            page: [],
            roleUsers: {},
            modalType: ''
        }
        this.baseState = this.state

    }

    async componentDidMount() {
        let url = window.location.pathname
        let id = url.substring(url.lastIndexOf('/') + 1);
        this.init(id)
    }

    componentDidUpdate(prevProps, prevState) {
        let url = window.location.pathname
        let id = url.substring(url.lastIndexOf('/') + 1);
        if (prevState.allEntity.length > 0 && this.state.allEntity.length === 0) {
            this.init(id)
        }
    }

    init = async (id) => {
        const entities = await Socket.read('Entity', { _id: id })

        const entityUser = await Socket.read('EntityUser', { entity_uuid: entities.data[0]._id })

        const entity = await Socket.read('Entity', { parent_id: entityUser.data[0].entity_uuid })

        this.setState({ entity: entities.data[0], admin: entityUser.data[0], allEntity: entity.data })
    }

    refactoData(value) {
        if (value) {
            const role = value.data && value.data[0].permission_wanup[0]
            const asArray = Object.entries(role);
            let data = []
            let data1 = []
            for (let i = 0; i < asArray.length; i++) {
                let value = asArray[i][0]
                let write = asArray[i][1].write
                let read = asArray[i][1].read
                if (write === true) data.push({ value, disabled: false })
                if (read === true) data1.push({ value, disabled: false })
            }
            return [data, data1]
        }
    }

    actionModal = async (event, e) => {
        event.stopPropagation();
        this.setState({ isModalVisible: !this.state.isModalVisible })

        const defaultValue = [{ value: 'Dashboard', disabled: false }, { value: 'Notifications', disabled: false }, { value: 'AccesInternet', disabled: false }];


        if (e.modal !== "") {
            const entity = await Socket.read("Entity", { _id: e.modal._id })

            const entityUser = await Socket.read("EntityUser", { entity_uuid: entity.data[0]._id })

            const user = await Socket.read("User", { _id: entityUser.data[0].user_uuid })

            const roles = await Socket.read('Roles', { _id: user.data[0].role_id })

            const data = this.refactoData(roles)

            this.setState(
                {
                    page: data.length > 0 ? data : defaultValue,
                    data: [user.data[0], entity.data[0]],
                    modal: user.data[0],
                    modalSociete: entity.data[0],
                    roleUsers: roles.data[0].permission_wanup[0],
                    type: e.modalType,
                    modalType: e.modalType
                })

        } else {
            this.setState({ isModalVisible: !this.state.isModalVisible, modal: e, modalType: e.modalType })
        }
    }

    handleCancel = () => {
        this.setState(this.baseState);
    }

    handleOk = () => {
        this.setState({ isModalVisible: false });
    }

    render() {
        return (
            <div>
                <div className="mt-5 mb-5" style={{ width: "20vw", margin: "0 auto" }}>
                    <Input type="search" onChange={(e) => this.setState({ query: e.target.value })} prefix={<FontAwesomeIcon icon={faSearch} />} placeholder=" Recherche" />
                </div>
                <div className="mt-3" style={{ display: "flex", flexDirection: "column" }}>
                    {
                        // this.props.entityId !== "b6c0811f-32ce-44be-beba-50398490d95d" &&
                        <>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <FontAwesomeIcon icon={faUserPlus} className="btn-add-users" onClick={(event) => this.actionModal(event, { modalType: "Nouveau client", modal: "" })} />
                            </div>
                            <div className="txt-add-users">
                                <p>Ajouter un client</p>
                            </div>
                        </>
                    }
                    <ModalFormClient
                        actionModal={this.state.isModalVisible}
                        handleCancel={this.handleCancel}
                        // handleOk={this.handleOk}
                        modal={this.state.modal}
                        modalSociete={this.state.modalSociete}
                        page={this.state.page}
                        roleUsers={this.state.roleUsers}
                        modalType={this.state.modalType}
                    />
                    <GestionSubClients
                        actionModal={this.actionModal}
                        modal={this.state.modal}
                        entity={this.state.allEntity}
                        query={this.state.query}
                    />
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        entities: state.CollectionsReducer.Entity,
        userInfos: state.NavbarReducer.userInfos,
        entityId: state.NavbarReducer.entity_id,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        data_entity: (data) => dispatch(data_entity(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(SubClients);
