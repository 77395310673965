import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';    

// import le reducer combiné
import reducer from './reducer/index';

export const middleware = [thunk];

export const createStoreWithMiddleWare = applyMiddleware(...middleware)(createStore);
    
export const store = createStoreWithMiddleWare(reducer);