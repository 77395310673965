
import React from 'react';
// import CircleSociety from '../../components/';
// import '../../components/home/home.scss';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
// import CircleSociety from '../../components/home/circleSociety';
import Portails from '../../components/Portails/Portails'
import User from '../../Utils/User/User';

class Home extends React.Component {

    componentDidMount() {
        document.title = "Sayse | Portails";
        !User.token() && this.props.history.push('/');
    }

    render() {
        return (
                <div>
                    <Portails {...this.props} />
                </div>
        )
    }
}

export default withRouter(props => <Home {...props} />);