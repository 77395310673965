import React from "react";
import { connect } from "react-redux";
import './gestion.scss';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faEdit, faUserSlash, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Button, Avatar } from "antd";
import { data_entity } from "../../../../store/action/navBarAction";
import NODATA from '../../../../assets/images/NODATA-BOX.svg'



const { Panel } = Collapse;

class GestionClients extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subClient: [],
            modalClients: '',
            query: '',
        }
    }

    header = (elements, i) => {
        const { dataClients, actionModal, dataSubClients } = this.props;
        return (
            <div className="headerPanel" key={i}>
                <div>
                    <Avatar className='Avatar' size="large" >
                        {elements.name.charAt(0).toUpperCase()}
                    </Avatar>
                </div>
                <p className="ml-3" style={{ fontWeight: "bold" }}>{elements.name.toUpperCase()}</p>
                <div style={{ position: "absolute", translate: "20vw" }}>
                    <Link
                        to={{
                            pathname: `/auth/clients/${elements._id}`,
                            query: { elements },
                        }}
                    >
                        <Button className="ml-2 mr-2" aria-label={`subclient${i}`}><FontAwesomeIcon icon={faUserFriends} /></Button>
                    </Link>
                    <Button className="ml-2 mr-2" aria-label={`editer${i}`} onClick={(event) => actionModal(event, { modalType: "modifications clients", modal: elements })} style={{ color: "#417bf6", borderColor: "#417bf6" }}>Éditer</Button>
                    {/* <Button className="ml-2 mr-2" style={{ color: "red", borderColor: "red", marginBottom: "4vh" }} >Supprimer</Button> */}
                </div>
            </div>
        )
    }

    contain = (elements, i) => {
        const { dataClients, actionModal, dataSubClients } = this.props;
        return (
            <>
                {
                    dataSubClients &&
                    dataSubClients.map((element) =>
                        element.parent_id === dataClients[i]._id &&
                        <div className="list-subClient" key={element.id}>
                            <div className="mr-3">
                                <Avatar className='Avatar' size="small" >
                                    {element.name.charAt(0).toUpperCase()}
                                </Avatar>
                            </div>
                            {/* <div style={{ display: "flex", alignItems: "baseline" }}> */}
                            <p className="mr-3" style={{ fontWeight: "bold" }}>{element.name.toUpperCase()}</p>
                            {/* </div> */}
                            <div style={{ display: "flex", position: "relative", marginRight: "3rem" }}>
                                <Link
                                    to={{
                                        pathname: `/auth/clients/${element._id}`,
                                        query: [elements]
                                    }}
                                >
                                    {/* <Button><FontAwesomeIcon icon={faUserFriends} /></Button> */}
                                    <Button className="ml-2 mr-3" aria-label={`subclient${i}`}><FontAwesomeIcon icon={faUserFriends} /></Button>
                                </Link>
                                <Button className="ml-2 mr-2" onClick={(event) => actionModal(event, { modalType: "modifications clients", modal: element })} style={{ color: "#417bf6", borderColor: "#417bf6" }}><FontAwesomeIcon icon={faUserEdit} /></Button>
                                {/* <Button className="ml-3 mr-2" style={{ color: "red", borderColor: "red", marginBottom: "2vh" }} ><FontAwesomeIcon icon={faUserSlash} /></Button> */}
                            </div>
                        </div>
                    )
                }
            </>
        )
    }

    render() {
        const { dataClients, actionModal, dataSubClients, query } = this.props;
        // const regex = new RegExp(query, 'i');
        // return regex.test(element.name)

        return (
            <div className="users-list-container">
                <Collapse ghost>

                    {dataClients.length > 0 ?
                        dataClients
                            .filter(element =>
                            // element.parent_id === this.props.userInfos.parent_id
                            {
                                const regex = new RegExp(query, 'i');
                                return regex.test(element.name)
                            })
                            .map((elements, i) => {
                                return (
                                    <Panel
                                        key={i}
                                        header={this.header(elements, i)}
                                    >
                                        {this.contain(elements, i)}
                                    </Panel>
                                )
                            }) : <div className="d-flex justify-content-center mt-5"><img src={NODATA} alt='no-data' width="150px" /></div>
                    }
                </Collapse>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        entities: state.CollectionsReducer.Entity,
        userInfos: state.NavbarReducer.userInfos,
        entityId: state.NavbarReducer.entity_id,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        data_entity: (data) => dispatch(data_entity(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(GestionClients);
