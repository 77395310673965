import React from "react";
import { connect } from "react-redux";
import { Button, Popconfirm } from "antd";
import AvatarProfile from "../../../../assets/images/Layout/profil.png";
import './gestion.scss';


const text = 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?';
const text1 = 'Êtes-vous sûr de vouloir réactiver ce compte ?';

class GestionUtilisateur extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            query: '',
            initLoading: true,
            loading: false,
            data: [],
            list: []
        }
    }

    componentDidMount() {

        document.title = "Sayse | Gestion utilisateurs"
        const datas = []

        for (let i = 0; i < 100; i++) {
            datas.push({
                key: i,
                name: `Edward King ${i}`,
                age: 32,
                address: `London, Park Lane no. ${i}`,
            });
        }

        this.setState({ data: datas })

    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props.datas !== prevProps.datas) {
            this.setState({ users: this.props.datas })
        }
    }


    render() {
        const { actionModal, deleteUsers, data, reactivate } = this.props
        const { users } = this.state
        let randomColor = Math.floor(Math.random() * 16777215).toString(16);

        const columns = [
            {
                dataIndex: 'name',
                width: 150,
            },
            {
                dataIndex: 'age',
                width: 150,
            },
            {
                dataIndex: 'address',
            },
        ];

        const styles = {
            active: 'blue',
            inactive: 'red'
        }

        // console.log(this.props.query)
        return (
            <div className="mb-5 users" style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
                {users.filter(element => {
                    const regex = new RegExp(this.props.query, 'i');
                    return regex.test(element.firstname) || regex.test(element.email) || regex.test(element.lastname)
                }).map((element, i) => {
                    return (
                        <div className="mt-1 p-3 row users-list" key={i}
                            style={{ backgroundColor: element.enabled === false && '#E9E7E7' }}
                        >
                            <p style={{ fontSize: "8px", fontWeight: "bold" }}>{element.profile}</p>
                            <div className="utilisateurs">
                                <img src={AvatarProfile} className="mx-auto" />
                            </div>
                            <div className="" style={{ lineHeight: "8px", width: "8vw" }}>

                                <p style={{ fontWeight: "bold", lineHeight: 1 }}>{element.firstname}</p>
                                <p style={{ fontWeight: "bold", lineHeight: 1 }}>{element.lastname !== "" ? element.lastname : ''}</p>
                                <p style={{ fontSize: "9px" }}>{element.email}</p>
                            </div>
                            {
                                element.enabled === true ?
                                    <div className="">
                                        <Button
                                            style={{ color: "#417bf6", borderColor: "#417bf6" }}
                                            onClick={() => actionModal({ type: 'update', data: element })}
                                            aria-label={`editer${i}`}
                                        >
                                            Éditer
                                        </Button>
                                        <Popconfirm placement="top" title={text} onConfirm={() => deleteUsers(element)} okText="Oui" cancelText="Non">
                                            <Button className="ml-2" style={{ color: "red", borderColor: "red" }}>Désactiver</Button>
                                        </Popconfirm>
                                    </div> :
                                    <Popconfirm placement="top" title={"Êtes-vous sûr de vouloir réactiver ce compte ?"} onConfirm={() => reactivate(element)} okText="Oui" cancelText="Non">
                                        <Button
                                            style={{ color: "#417bf6", borderColor: "#417bf6", width: '8vw' }}
                                            aria-label={`editer${i}`}
                                        >
                                            Réactiver
                                        </Button>
                                    </Popconfirm>
                            }
                        </div>)
                })
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.CollectionsReducer.User,
        entities: state.CollectionsReducer.Entity,
        entityId: state.NavbarReducer.entity_id,
        userInfos: state.NavbarReducer.userInfos,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(GestionUtilisateur);
