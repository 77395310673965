import React, { useCallback, useEffect, useState } from "react";
import "./Login.scss";
import { matchSorter } from "match-sorter";
import User from "../../Utils/User/User";
import { withRouter } from "react-router";
import Socket from "../../Utils/Socket/Socket";
import { Button, Form, Input } from "antd";
// @ts-ignore
import MDP from "../../assets/images/Cadenas2.svg";
// @ts-ignore
import LogoSayse from "../../assets/images/logo-sayse-white.svg";
// @ts-ignore
import UserPicto from "../../assets/images/User2.svg";
import Modal from "antd/es/modal/Modal";
import _ from "lodash";
import { UserLogin } from "../../store/types";

const Connexion = (props: any) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [output, setOutput] = useState("");
    const [toggleClasse, setToggleClasse] = useState(false);
    const [resetPwd, setRestPwd] = useState("");
    const [outputReset, setOutputReset] = useState("");
    const [outputResetsuccess, setOutputResetsuccess] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [debouncedState, setDebouncedState] = useState("");
    const [test, setTest] = useState("");
    const [verifPassword, setVerifPassword] = useState(false);

    useEffect(() => {
        document.title = "Sayse | Portail";
    }, []);

    const authError = (user: string) => {
        let error = false;
        const regex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (let [key, value] of Object.entries(user)) {
            if (value === "") {
                error = true;
            }
            if (key === "email") {
                if (!regex.test(value)) {
                    error = true;
                }
            }
        }
        return error;
    };

    const register = (user: string) => {
        return new Promise((resolve, reject) => {
            if (authError(user)) {
                reject("error field");
            } else {
                Socket.createUser(user).then((res) => {
                    resolve(res);
                });
            }
        });
    };

    const onFinish = (values: { email: string; password: string }) => {
        const { email, password } = values;
        const user = {
            email: email,
            password: password,
        };
        Socket.read("User", { email }).then((res) => {
            console.log(res)
            if (res.data && res.data.length > 0 && res.data[0].enabled) {
                User.login(user)
                    .then((res) => {
                        res.data === "connect" && localStorage.setItem("userToken", res.token);

                        props.history.push("/auth/home");
                    })
                    .catch((res) => {
                        console.error(res);
                        setOutput(res);
                    });
            } else {
                setOutput("Email ou mot de passe incorrect");
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const onFinishFailed = (errorInfo: any) => {};

    const toggleClass = (bool: boolean) => {
        setToggleClasse(bool);
    };

    const handleSubmitReset = (e: any) => {
        e.preventDefault();
        // User.createPassword(resetPwd)
        // setOutputResetsuccess('Un email vous a été envoyé')
        if (resetPwd === "") {
            setOutputReset("Veuillez entrer votre adresse email");
        } else {
            User.createPassword(resetPwd);
            setOutputResetsuccess("Un email vous a été envoyé");
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        setTest(target.value);
        debounce(target.value);
    };

    const debounce = useCallback(
        _.debounce((_searchVal) => {
            Socket.read("User")
                .then((res) => {
                    const filterUsers: Array<UserLogin> = matchSorter(res.data, _searchVal, { keys: ["email"] });
                    if (filterUsers.length === 1) {
                        if (filterUsers[0].password !== undefined) {
                            setVerifPassword(false);
                        } else {
                            setRestPwd(_searchVal);
                            setVerifPassword(true);
                        }
                    } else {
                        setVerifPassword(false);
                    }
                })
                .catch((error) => console.error(error));
        }, 500),
        []
    );

    return (
        <div className="connexion">
            <div className="formContainer">
                <img id="gif" src={LogoSayse} />
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        // label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Veuillez taper votre email !",
                            },
                        ]}
                    >
                        <Input placeholder="Identifiant" onChange={handleChange} prefix={<img src={UserPicto} />} />
                    </Form.Item>
                    {verifPassword === false ? (
                        <>
                            <Form.Item
                                // label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Veuillez taper votre mot de passe !",
                                    },
                                ]}
                            >
                                <Input.Password
                                    placeholder="Mot de passe"
                                    prefix={<img src={MDP} className={"logo_mdp_connexion"} />}
                                    visibilityToggle={false}
                                />
                            </Form.Item>
                            {output === "" ? (
                                <div className={"output-login-error output-login-error-out"}></div>
                            ) : (
                                <div className={"output-login-error output-login-error-in"} style={{ color: "red" }}>
                                    <small>{output}</small>
                                </div>
                            )}

                            <Form.Item>
                                <Button htmlType="submit">Se connecter</Button>
                            </Form.Item>
                        </>
                    ) : (
                        <>
                            <Form.Item>
                                <Button htmlType="submit" onClick={handleSubmitReset}>
                                    Créer votre mot de passe
                                </Button>
                            </Form.Item>
                            <small
                                className="success"
                                style={{ justifyContent: "center", marginLeft: "2.5vw", marginTop: "1vh" }}
                            >
                                {outputResetsuccess}
                            </small>
                        </>
                    )}
                </Form>

                <small id="toggler" onClick={() => toggleClass(true)}>
                    Mot de passe oublié ?
                </small>
            </div>

            <Modal
                wrapClassName={"reset_password_modal"}
                visible={toggleClasse}
                onOk={handleSubmitReset}
                onCancel={() => toggleClass(false)}
                footer={[
                    <Button key="submit" onClick={handleSubmitReset}>
                        Envoyer
                    </Button>,
                    <Button key="back" onClick={() => toggleClass(false)}>
                        Annuler
                    </Button>,
                ]}
            >
                <form action="" className="form_reset_password">
                    <h1>Mot de passe oublié ?</h1>
                    <label>Veuillez entrer votre adresse Email</label>
                    <input
                        type="text"
                        name="resetPwd"
                        className="input_mail"
                        id="emailReset"
                        value={resetPwd}
                        placeholder={"Entrez votre adresse mail"}
                        onChange={(e) => {
                            setRestPwd(e.target.value);
                        }}
                    />
                    <br />
                    <small id="output-reset" style={{ color: "red" }}>
                        {outputReset}
                    </small>
                    <small className="success" style={{ color: "green" }}>
                        {outputResetsuccess}
                    </small>
                </form>
            </Modal>
        </div>
    );
};

export default withRouter((props) => <Connexion {...props} />);
