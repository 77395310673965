import React from 'react';
import { Input } from 'antd';
import { debounce } from 'throttle-debounce';



class TextField extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            data: '',
        };
        this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState((prevState) => ({ ...prevState, data: this.props.value }));
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState((prevState) => ({ ...prevState, data: this.props.value }));
        }
    }
    autocompleteSearch = (data) => {
        this.props.changeValue(this.state.name, data);
    };

    changeQuery = (event) => {
        const name = event.target.name
        const value = event.target.value
        this.setState(
            () => ({ name: name, data: value }),
            () => {
                this.autocompleteSearchDebounced(this.state.data);
            },
        );
    };

    render() {
        const {placeholder, value, name, type, disabled, required, ariaLabel, inputProps, id} = this.props;
        let formProps = {};
        if (required) formProps = { ...formProps, 'data-required': 'true' };

        return (
            <Input
                aria-label={ariaLabel}
                inputprops={inputProps}
                // id={id}
                data-testid={id}
                className="mb-2"
                placeholder={placeholder}
                value={this.state.data}
                onChange={this.changeQuery}
                name={name}
                type={type}
                disabled={disabled}
                onClick={(event) => {
                    event.stopPropagation();
                }}
                {...formProps}
            />
        );
    }
}

export default TextField;