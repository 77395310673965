export const collectionsState = {
    Entity: [],
    EntityUser: [],
    AlertsNotifications: [],
    Connectedlogs: [],
    Roles: [],
    Categories_filtrageWeb: [],
    // Routage: [],
    Hash: [],
    Encryption: [],
    DhGroup: [],
    Protocols: [],
    // Categories_qos: [],
    Operators: [],
    Interconnexion: [],
    // Gateways: [],
    // Relais: [],
    // Dns: [],
    // Dhcp: [],
    // GroupPorts: [],
    // Dashboard: [],
    // GroupeNetwork: [],
    // Sites: [],
    Equipment: [],
    // Interface: [],
    // Links: [],
    // Ipsec: [],
    // Alerts: [],
    // Qos: [],
    // ModelBuilding: [],
    User: []
}


