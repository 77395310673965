export const collapseNavbar = (data) => {
    return {
        type: "COLLAPSE_NAVBAR",
        value: data
    }
}

export const updateNavbar = (data) => {
    return {
        type: "UPDATE",
        value: data
    }
}

export const menuCollapse = (data) => {
    return {
        type: "COLLAPSE_MENU",
        value: data
    }
}

export const translation = (data) => {
    return {
        type: "TRANSLATION",
        value: data
    }
}

export const theme = (data) => {
    return {
        type: "THEME",
        value: data
    }
}

export const entityId = (data) => {
    return {
        type: "ENTITY_ID",
        value: data
    }
}

export const selectValueDefaults = (data) => {
    return {
        type: "VALUE_DEFAULT",
        value: data
    }
}

export const data_entity = (data) => {
    return {
        type: "ENTITY_DATA",
        value: data
    }
}

export const titlePage = (data) => {
    return {
        type: "TITLE_PAGE",
        value: data
    }
}
export const userInfo = (data) => {
    return {
        type: "USER_INFO",
        value: data
    }
}
export const TransitionWanup = (data) => {
    return {
        type: "TRANSITION_WANUP",
        value: data
    }
}
export const SpiritTransition = (data) => {
    return {
        type: "TRANSITION_SPIRIT",
        value: data
    }
}
export const Video = (data) => {
    return {
        type: "VIDEO",
        value: data
    }
}
