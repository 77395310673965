import React from "react";
import { UK } from "../../translation/uk_lang";
import { FR } from "../../translation/fr_lang";
import { connect } from 'react-redux';
import "./gestionUtilisateurs.scss";
import { Tabs } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from "react-router-dom";
import Users from "../../components/gestionUtilisateurs/users/users";
import Clients from '../../components/gestionUtilisateurs/clients/clients'
import Socket, { socket } from '../../Utils/Socket/Socket';
import User from "../../Utils/User/User";



const { TabPane } = Tabs;

const tabs = [
    { info: [{ name: "Gestion Utilisateurs", tab: "Gestion Utilisateurs" }], component: Users },
    { info: [{ name: "Gestion Clients", tab: "Gestion Clients" }], component: Clients },
]

class Security extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileTranslate: !localStorage.getItem("lang") ? FR : localStorage.getItem("lang") === "fr" ? FR : UK,
            theme: !localStorage.getItem("theme") ? true : JSON.parse(localStorage.getItem("theme")) === true ? true : false
        }
    }

    componentDidMount() {
        this.getEntity();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({ theme: this.props.theme === true ? true : false });
        }
        if (prevProps.translation !== this.props.translation) {
            this.setState({ fileTranslate: this.props.translation === "uk" ? UK : FR });
        }
    }

    getEntity = async () => {
        let user = await User.userInfo();
        Socket.read("EntityUser", { entity_uuid: this.props.entityId })
            .then(res => {
                let entity_id = res.data;
                Socket.read("Entity", { _id: entity_id.map(e => e.entity_uuid) })
                    .then(res => {
                    })
            })
            .catch(error => console.error(error))
    }

    render() {
        return (
            <div id="Users">
                <div className="ml-5">
                    <NavLink
                        to={{
                            pathname: "/auth/home",
                            // aboutProps : elements
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowLeft}
                            //   onClick={this.context.router.history.goBack}
                            className="back-gestions-utilisateurs" style={{ color: "black" }} />
                    </NavLink>
                </div>
                <div className="users-content">
                    {this.state.fileTranslate.map(
                        (element, i) => (
                            <Tabs key={i}
                                defaultActiveKey="0"
                                className={!this.state.theme ? "tabs dark-slide" : "tabs"}
                            >
                                {tabs.map((tab, index) => {
                                    const Composent = tab.component;
                                    return (
                                        <TabPane tab={tab.info[0].name} key={index}>
                                            <Composent
                                                theme={this.state.theme}
                                                translation={this.state.fileTranslate}
                                            />
                                        </TabPane>
                                    )
                                })}
                            </Tabs>
                        )
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        translation: state.NavbarReducer.translation,
        theme: state.NavbarReducer.theme,
        buildingReducer: state.BuildingReducer,
        visibleModal: state.SecuriteReducer.visibleModalFireWall,
        entities: state.CollectionsReducer.Entity,
        entityId: state.NavbarReducer.entity_id,
    };
}

const mapDispatchToProps = dispatch => {
    return {

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Security);