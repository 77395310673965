import React from "react";
import { connect } from 'react-redux';
import "./gestionUsers";
import { Button, Popconfirm } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvatarProfile from "../../../../assets/images/Layout/profil.png";
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Socket from "../../../../Utils/Socket/Socket";
import { v4 as uuidv4 } from "uuid"
import User from "../../../../Utils/User/User";

const text = 'Are you sure to delete this users ?';

class SubUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            data: '',
            admin: '',
            userInfo: [],
        }
    }

    componentDidMount() {
        let url = window.location.pathname
        let id = url.substring(url.lastIndexOf('/') + 1);
        this.userInfo()
        this.props.getUsers(id)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.datas !== prevProps.datas) {
            this.setState({ users: this.props.datas })
        }
    }

    userInfo = async () => {
        let user = await User.userInfo();
        this.setState({ userInfo: user });
    }

    render() {
        const { actionModal, deleteUsers, datas, CheckAdminUser } = this.props
        const { users } = this.state
        return (
            <div className="mb-5" style={{ width: "35vw", margin: "0 auto", height: "45vh", overflowY: "scroll" }}>
                {
                    users.filter(element => {
                        const regex = new RegExp(this.props.query, 'i');
                        if (element) {
                            return regex.test(element.firstname) || regex.test(element.email) || regex.test(element.lastname)
                        } else {
                            return false;
                        }

                    }).map(element => {
                        return (
                            element && element.enabled === true &&

                            <div className="mt-1 p-3 row users-list" key={element.id}>
                                <div className="utilisateurs" key={element}>
                                    <img
                                        src={AvatarProfile}
                                        className="mx-auto" alt="photo profile" />
                                </div>
                                <div className="" style={{ lineHeight: "18px", width: "8vw" }}>
                                    {
                                        element.lastname !== undefined ? <p style={{ fontWeight: "bold" }}>{element.firstname + " " + element.lastname}</p> :
                                            <p style={{ fontWeight: "bold" }}>{element.firstname}</p>
                                    }
                                    <p style={{ fontSize: "9px" }}>{element.email}</p>
                                </div>
                                <div>
                                    <Button style={{ color: "#417bf6", borderColor: "#417bf6" }} onClick={() => actionModal({ type: 'update', data: element })}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                    <Popconfirm placement="top" title={text} onConfirm={() => deleteUsers(element)} okText="Yes" cancelText="No">
                                        <Button className="ml-2" style={{ color: "red", borderColor: "red" }}>supprimer</Button>
                                    </Popconfirm>
                                    {
                                        this.props.entityId === "b6c0811f-32ce-44be-beba-50398490d95d" && this.state.userInfo.profile !== "Administrateur" &&
                                        <Popconfirm placement="top" title={text} onConfirm={() => CheckAdminUser(element)} okText="Yes" cancelText="No">
                                            <Button className="ml-2" style={{ color: "red", borderColor: "red" }}>ajouter en admin</Button>
                                        </Popconfirm>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        entity_data: state.NavbarReducer.entity_data,
        entityId: state.NavbarReducer.entity_id,
    };
}

const mapDispatchToProps = dispatch => {
    return {

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SubUsers);