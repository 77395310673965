import React,  { FunctionComponent } from "react";
import "./layout.scss";
import {withRouter, RouteComponentProps} from "react-router";
import {connect, ConnectedProps} from "react-redux";
import {user} from "../../store/action/userAction";
import {UK} from "../../translation/uk_lang";
import {FR} from "../../translation/fr_lang";
import Socket from "../../Utils/Socket/Socket";
import User from "../../Utils/User/User";
import {udpateCollections, udpateCollectionsV2, updateEntity} from "../../store/reducer/CollectionsReducer/actions";
import Collections from "../../Utils/Collections/Collections";
import {isExpired} from "react-jwt";
import {notification} from 'antd';
import NavBar from "./navbar/navbar";
// @ts-ignore
import Wanup from '../../assets/wanup.mp4';
// @ts-ignore
import Spirit from '../../assets/spirit.mp4';

const mapStateToProps = (state: any) => {
    return {
        theme: state.NavbarReducer.theme,
        translation: state.NavbarReducer.translation,
        collapse: state.NavbarReducer.collapse,
        data: state.CollectionsReducer,
        status: state.CollectionsReducer.Status,
        notifications: state.CollectionsReducer.AlertsNotifications,
        entities: state.CollectionsReducer.Entity,
        entityId: state.NavbarReducer.entity_id,
        rowInfo: state.EquipementReducer.rowInfo,
        visibleSidebar: state.EquipementReducer.visibleSidebar,
        wanup: state.NavbarReducer.TransitionWanup,
        spirit: state.NavbarReducer.TransitionSpirit,
        video: state.NavbarReducer.video,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setUserInfos: (data : {}) => dispatch(user(data)),
        udpateCollection: (collection: string, data: any) => dispatch(udpateCollections(collection, data)),
        updateEntity: (data: string) => dispatch(updateEntity(data)),
        updateCollectionsV2: (data: string) => dispatch(udpateCollectionsV2(data))
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector> & RouteComponentProps<any>

const stateRefac = {
    'operationnal': "Opérationnel",
    'degrade': 'Dégradé',
    'offline': 'Hors ligne'
}

interface Props extends PropsFromRedux{
    children: any
}

interface State {
    // userInfo: Array,

}

class Layout extends React.PureComponent<Props, State> {
    constructor(props : any) {
        super(props);
        this.state = {
            // fileTranslate: !User.getStorageItem("lang")
            //     ? FR
            //     : User.getStorageItem("lang") === "fr"
            //         ? FR
            //         : UK,
            // theme: !User.getStorageItem("theme")
            //     ? true
            //     : JSON.parse(User.getStorageItem("theme")) === true
            //         ? true
            //         : false,
            // status: this.props.status && this.props.status,
            windowWidth: window.innerWidth 
        };

    }

    watchCrudLive() {
        Socket.live((res: any) => {
            if (res.collection === "Entity") {
                this.props.updateEntity(res.data[0])
            } else {
                //@ts-ignore
                this.props.updateCollectionsV2({[res.collection]: res.data})
            }
        })
    }

    componentDidMount() {
        this.testToken();
        !User.token() && this.props.history.push("/auth");
        !localStorage.getItem("lang") && localStorage.setItem("lang", "fr");
        // !localStorage.getItem("theme") && localStorage.setItem("theme", true);
        this.initProps();
        Collections.globalRead(this.props.updateCollectionsV2);
        Socket.readChange("Notifications", (res: any) => {
            Socket.readNotifications("Notifications").then(res => {
                // notification.open({
                //     className: "alert_notifications",
                //     message: <div className={"alert_notifications_title"}><div className={`icon_notification ${res.data.type}`}/><span>{stateRefac[res.data.type]}</span></div>,
                //     description: <div className={"describe_notifications_alert"}><span>{res.data.action}</span><br /><span>{res.data.description}</span></div>,
                //     closeIcon: "Fermer"
                // })
            }).catch(error => console.error(error))
        })
        this.setLogConnection();
        this.watchCrudLive();
    }

    testToken = () => {
        const token: any = User.token();
        if (!token) {
            if (token) {
                this.redirectVerifToken(token, false);
            } else {
                this.props.history.push("/auth/home");
            }
        } else {
            this.redirectVerifToken(User.token(), true);
        }
    }

    redirectVerifToken(token: any, exist: any) {
        User.checkValidateToken(token)
            .then((resToken) => {
                const token: any = User.token();
                if (token.exp < new Date().getTime() / 1000) {
                    this.props.history.push("/auth");
                } else if (resToken === "token valid") {
                    if (!exist) {
                        User.setToken(token);
                    }
                }
            })
            .catch(error => console.error('error'))
    }

    setLogConnection = async () => {
        let user = await User.userInfo();
        let linkEntityUser =
            this.props.data &&
            this.props.data.EntityUser.filter((element: any) => element.user_uuid === user._id).map((element: any) => element.entity_uuid);

        // const log = {
        //     createdAt: new Date(),
        //     login: user.email,
        //     ipAdress: localIpUrl(),
        //     user_agent: navigator.userAgent,
        //     role: user.profile,
        //     entity_uuid: linkEntityUser,
        // };
        // this.props.data.Connectedlogs.length > 0 &&
        // user &&
        // this.props.data.Connectedlogs[this.props.data.Connectedlogs.length - 1]
        //     .login !== user.email &&
        // Socket.insert("Connectedlogs", log.entity_uuid, log)
        //     .then(res => {
        //     })
        //     .catch(err => {
        //         console.error(err);
        //     })
    };

    displayNotifications = () => {
        notification.open({
            className: "alert_notifications",
            message: <div className={"alert_notifications_title"}><div className={`icon_notification critical`}/><span>Critique</span></div>,
            description: <div className={"describe_notifications_alert"}><span>Perte de connexion</span><br /><span>Un tel s'est connecter a </span></div>,
            duration: 0,
            closeIcon: "Fermer"
        })
    }   


    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.translation !== this.props.translation) {
            this.setState({
                fileTranslate: this.props.translation === "fr" ? FR : UK,
            });
        }

        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme === true ? true : false});
        }

        if (this.props !== prevProps) {
            this.initProps();
        }
        const token: any = User.token();

        if (isExpired(token) === true) {
            this.props.history.push('/');
        }
    }

    initProps = () => {
        const {props} = this;
        Socket.getProps(props);
        User.getProps(props);
        Collections.getProps(props);
    };

    transiton() {
        const {wanup, spirit} = this.props;
        if(wanup) {
            return (
                <div className="fullscreen-bg">
                    <video loop muted autoPlay className="fullscreen-bg__video">
                        <source src={Wanup} type="video/webm"/>
                    </video>
                </div>
            )
        } else if (spirit){
            return (
                <div className="fullscreen-bg">
                    <video loop muted autoPlay className="fullscreen-bg__video">
                        <source src={Wanup} type="video/webm"/>
                    </video>
                </div>
            )
        }

        return false
    }

    render() {
        const {entities, entityId} = this.props;
        // const entity = entities.find((e) => e._id === entityId);
        return (
            <div id="byos-layout" >
                {
                    this.props.video === true ?
                    <div className="fullscreen-bg">
                        {
                            this.props.wanup === true ?
                            <video loop muted autoPlay className="fullscreen-bg__video">
                                <source src={Wanup} type="video/webm"/>
                            </video> :
                             <video loop muted autoPlay className="fullscreen-bg__video">
                                <source src={Spirit} type="video/webm"/>
                            </video>
                        }
                    </div> :
                    <div>
                    <NavBar data-test="navbar"/>
                    <div>
                        {this.props.children}
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default connector(withRouter((props: any) => <Layout {...props} />));
