const initialState = {
    visible: "",
    modalTypeAdmin: ""
}

const AdminReducer = (state = { initialState }, action) => {
    let newState;
    switch (action.type) {
        case "VISIBLE_DRAWER_ADMIN":
            newState = {
                ...state,
                visible: action.value
            }
            return newState;
        case "MODAL_TYPE_ADMIN":
            newState = {
                ...state,
                modalTypeAdmin: action.value
            }
            return newState;
        default:
            return state;
    }
}

export default AdminReducer;