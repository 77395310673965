export const initialState = {
    visibleDetails: false,
    openModalUser: false,
    addUser: {
        lastname: '',
        firstname: '',
        email: '',
        mobile: '',
        password: '',
        picture: null,
        role: null,
        enabled: null,
        parent_id: null
    },
    userData: '',
    rolesEntity: []
}

const SettingReducer = (state = { initialState }, action) => {
    let newState;
    switch (action.type) {
        case "VISIBLE_DETAILS":
            newState = {
                ...state,
                visibleDetails: action.value
            }
            return newState;
        case "VISIBLE_MODALS":
            newState = {
                ...state,
                visibleModals: action.value
            }
            return newState;
        case "VISIBLE_VIEW":
            newState = {
                ...state,
                visibleView: action.value
            }
            return newState;
        case "VISIBLE_EDIT":
            newState = {
                ...state,
                visibleEdit: action.value
            }
            return newState;
        case "VISIBLE_ROLES_USERS":
            newState = {
                ...state,
                visibleRolesUsers: action.value
            }
            return newState;
        case "VISIBLE_ADD_USERS":
            newState = {
                ...state,
                visibleAddUsers: action.value
            }
            return newState;
        case "SETTINGS":
            newState = {
                ...state,
                settings: action.value
            }
            // 
            return newState;
        default:
            return state;
    }
}

export default SettingReducer;