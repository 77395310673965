import React from "react";
import { connect, ConnectedProps } from "react-redux";
import {RouteComponentProps} from "react-router";
import { Input, Button, notification, Space } from 'antd';
import axios from 'axios';
import './compte.scss';
import { updateNavbar } from "../../store/action/navBarAction";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// @ts-ignore
import AvatarProfile from "../../assets/images/Layout/profil.png"
import User from '../../Utils/User/User';
import Socket from '../../Utils/Socket/Socket';
import {userInfoType} from "../../types";
import TextField from "../../common/TextField/TextField";


//@ts-ignore
const openNotificationWithIcon = type => {
    //@ts-ignore
    notification[type]({
        style: {marginTop: '6rem'},
        description: 'Vos informations on bien été enregistré',
    });
  };
const mapStateToProps = (state : any) => {
    return {
        entities: state.CollectionsReducer.Entity,
        udpateuser: state.NavbarReducer.update,
        entityId: state.NavbarReducer.entity_id,
        userInfos: state.NavbarReducer.userInfos,
    };
};

const mapDispatchToprops = (dispatch : any) => {
    return {
        updateNavbar: (nb : number) => dispatch(updateNavbar(nb))
    };
};

const connector = connect(mapStateToProps, mapDispatchToprops)

type PropsFromRedux = ConnectedProps<typeof connector> & RouteComponentProps<any>

interface Props extends PropsFromRedux {
    userInfos : userInfoType
    udpateuser : number
}

interface State {
    // userInfo: Array,
    societe: string,
    files: any,
    entityId: string,
    arrayInfo:  userInfoType,
    imgValide: boolean,
    img: string,
    alertMsg: string
    userInfo: userInfoType
}

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}
// exports.module = {
//     //@ts-ignore
//     test()
// }

class Compte extends React.PureComponent<Props, State> {
    constructor(props : any) {
        super(props);
        this.state = {
            userInfo : this.props.userInfos && this.props.userInfos,
            arrayInfo: this.props.userInfos && this.props.userInfos,
            societe: '',
            files: '',
            entityId: '',
            imgValide: false,
            img: '',
            alertMsg: '',
        }
    }

    componentDidMount() {
        document.title = "Sayse | Mon compte"
       this.userInfo();
       this.checkImageValide();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if(prevProps.userInfos !== this.props.userInfos) {
           this.setState({arrayInfo : this.props.userInfos})
        }
    }

    checkImageValide = async () => {
        let user = await User.userInfo();
        let img;

            img = axios.get(`${process.env.REACT_APP_BACK_URL_IMG}user/${user && user._id + '.png'}`)

            img.then(res => {
                if(res.status === 200) {
                    this.setState({imgValide : true})
                }
            })
            .catch(error => {
                console.error("error 404")
            })
    }

    userInfo = async () => {
        let user = await User.userInfo();
        if(user.pipe_drive_org_id) {
            const entities =  await Socket.read("Entity", { pipe_drive_org_id: user.pipe_drive_org_id }) 
            try {
                this.setState({userInfo : user, arrayInfo : user, entityId : entities.data[0]._id, societe: entities.data[0].name})
            } catch(error) {
                console.error(error);
            }
        } else {
            const entityUsers = await Socket.read("EntityUser", { user_uuid: user._id })
            const entities = await Socket.read("Entity", { _id: entityUsers.data[0].entity_uuid })
            try {
                this.setState(() => ({entityId : entities.data[0]._id, societe: entities.data[0].name, userInfo : user}))
            } catch(error) {
                console.error(error);
            } 
        }
    }

    handleChange = (name: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //@ts-ignore
        this.setState((state) => ({arrayInfo : {...state.arrayInfo, [name] : value}}))
    }

    validChange = async (e: any) => {
        e.preventDefault()
        const {updateNavbar} = this.props;
        const {files, userInfo, arrayInfo, entityId} = this.state
        
        const {firstname, lastname ,email, mobile, profile, _id} = arrayInfo


        const data = { firstname, lastname, email, mobile, profile }

        
        if(arrayInfo) {
        Socket.update("User", _id, entityId, data)
            .then(res => {
                openNotificationWithIcon('success')
            })
            .catch(error => console.error(error))

        if (files !== '') {
            const data = new FormData()
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            data.append('file', files)
            const userId = userInfo._id;

            try {
                const res = await axios.post(`${process.env.REACT_APP_BACK_URL_IMG}api/users/upload/${userId}`, data, config)

                openNotificationWithIcon('success')
                updateNavbar(res.data.date)
            } catch (error) {
                this.setState({ alertMsg: "Une erreur s'est produite pendant la mise à jour de la photo." })
            }
        }
    }
    }

    test = () => {
        return 2
    }

    uploadImage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target as HTMLInputElement;

        const file: File = (target.files as FileList)[0];
        this.setState({ files: file })
    }

    deletePicture = () => {
    }

    render() {
        const {userInfos} = this.props
        const {imgValide, files, societe} = this.state
            return (
                <div className='compte' data-testid="test-1">
                    <div className="ml-5">
                        <NavLink to={{pathname: "/auth/home"}}>
                            <FontAwesomeIcon icon={faArrowLeft} className="back-gestions-utilisateurs" style={{color:"black"}}/>
                        </NavLink>
                    </div>
                    <div className="ctn-2">
                        <div className="ctn-img">
                            <img
                                src={
                                    userInfos && 
                                    files ? URL.createObjectURL(files)
                                    : imgValide === true  ? `${process.env.REACT_APP_BACK_URL_IMG}user/${userInfos && userInfos._id + '.png'}?${global.Date.now()}`
                                    : AvatarProfile
                                }
                                className="cercle" />
        
                            <div className={"ctn-img-actions"}>
                                <div className={"ctn-img-buttons"}>
                                    <label htmlFor="file">Choisir une image</label>
                                        {/* <input data-testid="photo-uploader" name={"file"} className={"inputfile"} type="file" onChange={e => this.uploadImage(e)} /> */}
                                        <input id={"file"} name={"file"} className={"inputfile"} type="file" onChange={e => this.uploadImage(e)} />
                                        <button onClick={this.deletePicture}>Supprimer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form className="" style={{width: "35vw", margin: "0 auto"}}>
                        <div className="row">
                            <div className="col">
                                <label>Prénom *</label>
                                <TextField
                                    placeholder="Prénom" 
                                    value={userInfos && userInfos.firstname}
                                    name={'firstname'}
                                    changeValue={this.handleChange}
                                    type={'text'}
                                    required={true}
                                    ariaLabel={`Prénom`}
                                    inputProps={{ "data-testid": "content-input" }}
                                    id="content"
                                />
                            </div>
                            <div className="col">
                                <label>Nom *</label>
                                <TextField
                                    placeholder="Nom" 
                                    value={userInfos &&  userInfos.lastname}
                                    name={'lastname'}
                                    changeValue={this.handleChange}
                                    type={'text'}
                                    required={true}
                                    ariaLabel={`Nom`}
                                    inputProps={{ "data-testid": "content-input" }}
                                    id="content"
                                />
                            </div>
                        </div>

                        <div className="form-group mt-3">
                            <label>Adresse mail *</label>
                            <TextField
                                    placeholder="Email" 
                                    value={userInfos &&  userInfos.email}
                                    name={'email'}
                                    changeValue={this.handleChange}
                                    type={'email'}
                                    disabled={true}
                                    ariaLabel={`Email`}
                                    inputProps={{ "data-testid": "content--input" }}
                                    id="content"
                                />
                        </div>
                        <div className="form-group mt-3">
                            <label>Numéro de téléphone *</label>
                            <TextField
                                    placeholder="Téléphone" 
                                    value={userInfos &&  userInfos.mobile}
                                    name={'phone'}
                                    changeValue={this.handleChange}
                                    type={'phone'}
                                    ariaLabel={`Téléphone`}
                                    inputProps={{ "data-testid": "content---input" }}
                                    id="content"
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Société *</label>
                            <Input type="text" className="form-control" name="societe" id="exampleInputEmail1" aria-describedby="emailHelp" value={societe} disabled/>
                        </div>
                        <div className="" style={{display:'flex', justifyContent:'center'}}>
                            <Button className="btn btn-primary btn-compte" id="submit" onClick={this.validChange}>Enregistrer mes informations</Button>
                            <div className="ml-2">
                            <Link to={{pathname: '/auth/reset-password/'}}>
                                <Button className="btn btn-update">Modifier votre mot de passe</Button>
                            </Link>
                            </div>
                        </div>
                    </form>
                </div>
            )
    }
}

export default connector(Compte);

// module.exports = {
//     //@ts-ignore
//     test:  () => 
// }