import React from "react";
import { connect } from "react-redux";
import '../gestionUtilisateurs.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Input, Collapse, Avatar } from "antd";
import ModalForm from "../../../common/ModalFormClient/ModalFormClient";
import Socket from "../../../Utils/Socket/Socket";
import { data_entity } from "../../../store/action/navBarAction";
import GestionClients from './gestion/gestionClients'



const { Panel } = Collapse;

class Clients extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            entitiesClient: [],
            subClient: [],
            modal: '',
            modalSociete: {},
            page: '',
            query: '',
            roleUsers: '',
            data: '',
            modalType: '',
            services: [],
            parent_id: "",
            rightForCreate: false,

        }
        this.baseState = this.state
    }

    componentDidMount() {
        this.societeInfo();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.entitiesClient.length > 0 && this.state.entitiesClient.length === 0) {
            this.societeInfo();
        }
    }

    refactoData = (value) => {
        if (value) {
            const role = value.data && value.data[0].permission_wanup[0]
            const asArray = Object.entries(role);
            let data = []
            let data1 = []
            for (let i = 0; i < asArray.length; i++) {
                let value = asArray[i][0]
                let write = asArray[i][1].write
                let read = asArray[i][1].read
                if (write === true) data.push({ value })
                if (read === true) data1.push({ value })
            }
            return [data, data1]
        }
    }

    actionModal = async (event, e) => {
        event.stopPropagation();
        const defaultValue = [{ value: 'Dashboard' }, { value: 'Notifications' }, { value: 'AccesInternet' }];

        if (Object.keys(e.modal).length > 0) {
            const entity = await Socket.read("Entity", { _id: e.modal._id })
            const entityUser = await Socket.read("EntityUser", { entity_uuid: entity.data[0]._id })
            const user = await Socket.read("User", { _id: entityUser.data[0].user_uuid })
            const roles = await Socket.read('Roles', { _id: user.data[0].role_id })
            const data = this.refactoData(roles)

            this.setState(
                {
                    page: data.length > 0 ? data : defaultValue,
                    data: [user, entity.data[0]],
                    modal: user.data[0],
                    modalSociete: entity.data[0],
                    roleUsers: roles.data[0].permission_wanup[0],
                    modalType: e.modalType,
                    services: [roles.data[0].service[0]]
                })
        } else {
            this.setState({ isModalVisible: !this.state.isModalVisible, modal: e.modal, modalType: e.modalType })
        }
        this.setState({ isModalVisible: !this.state.isModalVisible })
    }

    societeInfo = async () => {
        const currentEntities = await Socket.read('Entity', { _id: this.props.userInfos.EntityUser.map(e => e.entity_uuid) })

        if (currentEntities.data[0].profile === "super_admin") {
            const entities = await Socket.read('Entity', { parent_id: currentEntities.data[0]._id })
            const entityUser = await Socket.read('EntityUser', { entity_uuid: entities.data.map(e => e._id) })
            const entitySubReseller = await Socket.read('Entity', { parent_id: entityUser.data.map(e => e.entity_uuid) })
            const users = await Socket.read('User', { _id: entitySubReseller.data.map(e => e.user_uuid) })

            entities.data.sort((a, b) => a.name.localeCompare(b.name))
            entitySubReseller.data.sort((a, b) => a.name.localeCompare(b.name))

            this.setState({ subUsers: users.data, subClient: entitySubReseller.data, entitiesClient: entities.data })
        }
        else if (currentEntities.data[0].profile === "revendeur") {
            const entities = await Socket.read('Entity', { parent_id: currentEntities.data.map(e => e._id) })
            const entityUser = await Socket.read('EntityUser', { entity_uuid: entities.data.map(e => e._id) })
            const entitySubReseller = await Socket.read('Entity', { parent_id: entityUser.data.map(e => e.entity_uuid) })
            const users = await Socket.read('User', { _id: entitySubReseller.data.map(e => e.user_uuid) })

            entities.data.sort((a, b) => a.name.localeCompare(b.name))
            entitySubReseller.data.sort((a, b) => a.name.localeCompare(b.name))

            this.setState({ subUsers: users.data, subClient: entitySubReseller.data, entitiesClient: entities.data, rightForCreate: true })
        }
        else {
            const entityUser = await Socket.read('EntityUser', { entity_uuid: currentEntities.data.map(e => e._id) })
            const entitySubReseller = await Socket.read('Entity', { parent_id: entityUser.data.map(e => e.entity_uuid) })
            const users = await Socket.read('User', { _id: entitySubReseller.data.map(e => e.user_uuid) })

            entitySubReseller.data.sort((a, b) => a.name.localeCompare(b.name))

            this.setState({ subUsers: users.data, subClient: entitySubReseller.data })
        }
        this.setState({ parent_id: currentEntities.data[0]._id })
    }

    handleCancel = () => {
        this.setState(this.baseState);
    }

    render() {
        return (
            <div className=''>
                <div className="mt-5 mb-5" style={{ width: "20vw", margin: "0 auto" }}>
                    <Input type="search" onChange={(e) => this.setState({ query: e.target.value })} prefix={<FontAwesomeIcon icon={faSearch} />} placeholder=" Recherche" />
                </div>

                <div className="mt-3" style={{ display: "flex", flexDirection: "column" }}>
                    {
                        this.state.rightForCreate &&
                        <>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <FontAwesomeIcon icon={faUserPlus} className="btn-add-users" onClick={(event) => this.actionModal(event, { modalType: "Nouveau client", modal: "" })} />
                            </div>
                            <div className="txt-add-users">
                                <p>Ajouter un client</p>
                            </div>
                        </>
                    }
                    <ModalForm
                        actionModal={this.state.isModalVisible}
                        handleCancel={this.handleCancel}
                        modal={this.state.modal}
                        modalSociete={this.state.modalSociete}
                        page={this.state.page}
                        roleUsers={this.state.roleUsers}
                        entityId={this.props.entityId}
                        modalType={this.state.modalType}
                        services={this.state.services}
                        parent_id={this.state.parent_id}
                    />
                    <GestionClients
                        actionModal={this.actionModal}
                        dataSubClients={this.state.subClient}
                        modal={this.state.modal}
                        dataClients={this.state.entitiesClient}
                        query={this.state.query}
                    />
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        entities: state.CollectionsReducer.Entity,
        userInfos: state.NavbarReducer.userInfos,
        entityId: state.NavbarReducer.entity_id,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        data_entity: (data) => dispatch(data_entity(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(Clients);
