import { combineReducers } from 'redux';

import NavbarReducer from './navbarReducer';
import AppReducer from './appReducer';
import EquipementReducer from './equipementReducer';
import BuildingReducer from './buildingReducer';
import DashboardReducer from './dashboardReducer';
import CollectionsReducer from './CollectionsReducer/collectionsReducer';
import SelectReducer from './selectReducer';
import AdminReducer from './adminReducer';
import AlertReducer from './alertReducer';
import ReportingReducer from './reportingReducer';
import SettingReducer from './settingReducer'
import LaboReducer from './laboReducer';
import SecuriteReducer from './securiteReducer'


export default combineReducers ({
    NavbarReducer,
    AppReducer,
    EquipementReducer,
    BuildingReducer,
    CollectionsReducer,
    DashboardReducer,
    SelectReducer,
    AdminReducer,
    AlertReducer,
    ReportingReducer,
    SettingReducer,
    SecuriteReducer,
    LaboReducer,
})