import React from "react";
import { connect } from "react-redux";
import '../gestionUtilisateurs.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Input, message } from "antd";
import Socket, { socket } from '../../../Utils/Socket/Socket';
import ModalForm from '../../../common/ModalForm/ModalForm';
import User from "../../../Utils/User/User";
import axios from 'axios'
import GestionSubUsers from "./gestions/gestionSubUsers";
import { v4 as uuidv4 } from "uuid"
import UserForm from "./UserForm/UserForm";


const text = 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?';

class UsersClient extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            userInfo: [],
            allUsers: [],
            data: '',
            roleUsers: '',
            typeCreate: '',
            img: '',
            tests: [],
            query: '',
            page: '',
            imgValide : false,
            users: [],
            url: '',
            idEntity: ''
        }
    }


    componentDidMount() {
        let url = window.location.pathname
        let id = url.substring(url.lastIndexOf('/') + 1);
        this.setState(() => ({idEntity : id}))
        this.getUsersOfClients(id);
    }
    

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.data !== prevProps.data) {
            let user = await User.userInfo();

            let data = this.props.data.filter(e => e.parent_id === user._id && e.enabled !== false)
            let url = window.location.pathname
            let id = url.substring(url.lastIndexOf('/') + 1);
            this.setState(() => ({idEntity : id}))
            this.getUsersOfClients(id);
            this.setState({ allUsers: data })
        }
    }

    getUsersOfClients = async (id) => {
  
            if(id) {
                const resEntityUsers = await Socket.read('EntityUser', {entity_uuid: id});

                const usersPromises = resEntityUsers.data.map(entityUser => Socket.read('User', {_id: entityUser.user_uuid}))
                try {
                    const res = await Promise.all(usersPromises);
                    const users = res.map(r => r.data[0]);
                    this.setState(() => ({users: users}))
                } catch (error) {
                    console.error(error);
                }
            } else {
                Socket.read('User', {parent_id : 'bf7fb623-d06b-479d-95d0-087aeac1e53c'})
                .then(res => {
                    this.setState({users : res.data})
                })
                .catch(error => console.error(error))
            }

    }

    actionModal = async (e) => {
        this.setState({ isModalVisible: !this.state.isModalVisible, data: e })
        const defaultValue = [{value : 'Dashboard', disabled: false}, {value : 'Notifications', disabled: false}, {value : 'AccesInternet', disabled: false}];
        if (e.data) {
            this.checkImageValide(e.data._id)
            const roles = await Socket.read('Roles', { _id: e.data.role_id })
            const IdRole = roles.data[0]._id

            Socket.read('Roles', { _id: IdRole })
                .then(res => {
                    const data = this.refactoData(res)
                    this.setState({ page: data.length > 0 ? data : defaultValue, roleUsers: res.data[0].permission_wanup })
                })
                .catch(error => console.error(error));
        } else {
            this.setState({ roleUsers: [], page: defaultValue })
        }
    }

    checkImageValide = (id) => {
        let img;
        img = axios.get(`${process.env.REACT_APP_BACK_URL_IMG}user/${id && id + '.png'}`)
        img.then(res => {if(res.status === 200) this.setState({imgValide : true})})
        .catch(error => this.setState({imgValide : false}))
    }

    deleteUsers = (e) => {
        message.info('Utilisateur supprimé.');
        Socket.update('User', e._id, null, { enabled: false })
            .then(res => {

                Socket.update('EntityUser', e._id, null, { enabled: false })
                    .then(res => {
                    })
                    .catch(error => console.error(error))

            })
            .catch(error => console.error(error))
    }

    handleCancel = () => {
        this.setState({ isModalVisible: false, page : [] });
    }

    handleOk = () => {
        this.setState({ isModalVisible: false });
    }

    refactoData(value) {
        if(value) {
            const role = value.data && value.data[0].permission_wanup[0]
            const asArray = Object.entries(role);
            let data = []
            let data1 = []
            for(let i = 0; i < asArray.length; i++) {
                let value = asArray[i][0]
                let write = asArray[i][1].write
                let read = asArray[i][1].read
                if(write === true) data.push({value, disabled : false})
                if(read === true) data1.push({value, disabled : false})
            }
            return [data, data1]
        }
    }

    CheckAdminUser = (element) => {
        
        const admin = {
            ...element,
            parent_id :  uuidv4(),
            profile : "Administrateur"
        }


        Socket.update("User", element._id, null, admin)
            .then(res => {
                let data = []

                if(res) {
                    for (let i = 0; i < this.state.users.length; i++) {
                        if (this.state.users[i]._id !== element._id) {
                            data.push(this.state.users[i]);
                        }
                    }

                    data.length > 0 &&
                    Socket.remove('User', this.props.entityId, data.map(e => e._id))
                    .then(res => console.info(res))
                    .catch(error => console.error(error))
                }
                
            })
            .catch(error => console.error(error))
    }

     render() {
        const {users} = this.state
            return (
                <div className='gestionUsers'>
                   <div className="search-input mt-5 mb-5" style={{width: "20vw", margin:"0 auto"}}>
                   <Input type="search" onChange={(e) => this.setState({query : e.target.value})} prefix={<FontAwesomeIcon icon={faSearch} />} placeholder=" Recherche"/>
                   </div>
                   <div className="mt-2" style={{display : "flex", flexDirection: "column"}}>
                       <div style={{display: "flex", justifyContent: "center"}}>
                            <FontAwesomeIcon icon={faUserPlus}  className="btn-add-users" onClick={() => this.actionModal({type : 'add', data : ""})}/> 
                       </div>
                       <div className="txt-add-users" onClick={() => this.actionModal({type : 'add', data : ""})}>
                           <p>Ajout d'un utilisateur</p>
                       </div>
                        {/*<ModalForm */}
                        {/*    actionModal={this.state.isModalVisible} */}
                        {/*    modal={this.state.data} */}
                        {/*    roleUsers={this.state.roleUsers}*/}
                        {/*    page={this.state.page}*/}
                        {/*    handleCancel={this.handleCancel} */}
                        {/*    handleOk={this.handleOk}*/}
                        {/*    idEntity={this.state.idEntity}*/}
                        {/*    imgValide={this.state.imgValide}*/}
                        {/*/>*/}
                       <UserForm
                           closeForm={this.handleCancel} formData={this.state.data}
                           formVisible={this.state.isModalVisible} imgValide={this.state.imgValide}
                           cancelUserForm={this.handleCancel} subEntityID={this.state.idEntity}/>
                        <GestionSubUsers 
                            actionModal={this.actionModal} 
                            deleteUsers={this.deleteUsers}
                            getUsers={this.getUsersOfClients} 
                            datas={users}
                            query={this.state.query}
                        />
                   </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.CollectionsReducer.User,
        entities: state.CollectionsReducer.Entity,
        entityId: state.NavbarReducer.entity_id,
        userInfos: state.NavbarReducer.userInfos,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(UsersClient);
