const initialState = {
    user: ""
}

const AppReducer = (state = { initialState }, action) => {
    let newState;
    switch (action.type) {
        case "USER_INFOS":
            newState = {
                ...state,
                user: action.value
            }
            return newState;
        default:
            return state;
    }
}

export default AppReducer;