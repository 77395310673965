import Socket from "../Socket/Socket";
import {collectionsState} from '../../store/reducer/CollectionsReducer/state';
import {ImportsNotUsedAsValues} from "typescript";
import {entityId} from "../../store/action/navBarAction";

class Collections {

    static getProps(props) {
        this.props = props;
    }

    static async globalRead(updateCollections) {
        const promises = []
        console.time('t')
        for (let [key, value] of Object.entries(collectionsState)) {
            if (key !== "Entity") {
                const res = Socket.readTestToken(key, {});
                promises.push(res)
            }
        }
        Promise.all(promises).then(res => {
            let newCollections = {};
            for(let col of res) {
                newCollections = {...newCollections, [`${col.collection}`]: col.data};
            }
            console.timeEnd('t')
            updateCollections(newCollections)
        })
    }

    static findRelation = (collection, id) => {
        const {collectionsReducer} = this.props;
        if (collectionsReducer[collection].filter(item => item._id === id)) {
            return collectionsReducer[collection].filter(item => item._id === id)[0];
        }
    }

    static flatRecursive = (a, searchKey, t, entityId) => {
        if (Array.isArray(a)) {
            for (let i = 0; i < a.length; i++) {
                if (a[i].profile && !entityId) {
                    entityId = a[i]._id;
                }
                if (typeof a[i] === "object" && !Array.isArray(a[i])) {
                    for (let [key, value] of Object.entries(a[i])) {
                        if (key === searchKey) {
                            if (value.length > 0) {
                                value = Array.from(value, (x) => ({...x, entityId}))
                            }
                            t.push(...value);
                        }
                        if (typeof value === "object" && value) {
                            this.flatRecursive(value, searchKey, t, entityId);
                        }
                    }
                }
            }
        }
        return t;
    };
}

export default Collections;