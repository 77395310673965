import * as React from 'react';
import './Login.scss';
import Connexion from '../../components/Login/Login';


class Login extends React.Component{
    componentDidMount() {
        document.title = "Sayse | Login";
    }
    render(){
        return(
            <Connexion />
        );

    }
}

export default Login;