import React from "react";
import "./navbar.scss"
import axios from 'axios';
import { collapseNavbar, entityId, menuCollapse, userInfo } from "../../../store/action/navBarAction";
import { faPowerOff, faUser, faHome, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  NavLink, withRouter } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import {RouteComponentProps} from "react-router";
// @ts-ignore
import AvatarProfile from "../../../assets/images/Layout/profil.png";
import { Entities, userInfoType } from "../../../store/types";
import Socket from '../../../Utils/Socket/Socket';
import User from "../../../Utils/User/User";

const mapStateToProps = (state : any) => {
  return {
      collapse: state.NavbarReducer.collapse,
      menuCollapse: state.NavbarReducer.menuCollapse,
      // users: state.AppReducer.user,
      translation: state.NavbarReducer.translation,
      theme: state.NavbarReducer.theme,
      entityId: state.NavbarReducer.entity_id,
      title: state.NavbarReducer.title,
      visibleSidebar: state.EquipementReducer.visibleSidebar,
      collectionsReducer: state.CollectionsReducer,
      entities: state.CollectionsReducer.Entity,
      udpateuser: state.NavbarReducer.update,
      users: state.NavbarReducer.user,
  }
}

const mapDispatchToProps = (dispatch : any) => {
  return {
      setCollapse: (bool : boolean) => dispatch(collapseNavbar(bool)),
      setMenuCollapse: (bool : boolean) => dispatch(menuCollapse(bool)),
      setUserinfo: (data : userInfoType) => dispatch(userInfo(data)),
      setEntityId: (data : string) => dispatch(entityId(data)),
      // setTranslate: (data) => dispatch(translation(data)),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector> & RouteComponentProps<any>

interface Props extends PropsFromRedux {
    userInfo : userInfoType
    udpateuser : number,
    entities : Entities
}

interface State {
    textChange : boolean,
    imgValide : boolean,
    userInfo : userInfoType,
    collapse : boolean
}

class Navbar extends React.PureComponent<Props, State> {
  constructor(props : any) {
    super(props)
    this.state = {
        textChange: false,
        imgValide: false,
        collapse: false,
        userInfo : {
          _id : "",
          firstname : "",
          email : "",
          role_id : "",
          profile : "",
        },
        // fileTranslate: !User.getStorageItem("lang")
        //     ? FR
        //     : User.getStorageItem("lang") === "fr"
        //         ? FR
        //         : UK,
        // theme: !User.getStorageItem("theme")
        //     ? true
        //     : JSON.parse(User.getStorageItem("theme")) === true
        //         ? true
        //         : false,
    }
}
    menuCollapsing = (e: any) => {
      const pic = document.querySelector('div[class="profile-menu"]')
      if (pic && !pic.contains(e.target)) {
          this.props.setMenuCollapse(false)
          return e.isTrusted 
      }
    }

    userInfo = async () => {
      let user = await User.userInfo();
      this.setState({userInfo : user});
      
      Socket.read("Entity", { pipe_drive_org_id: user.pipe_drive_org_id })
        .then(res => {
            // this.setState({entityId : res.data[0]._id, societe: res.data[0].name})
            this.props.setEntityId(res.data[0]._id)
        })

      this.props.setUserinfo(user)
    } 

    checkImageValide = async () => {
      let user = await User.userInfo();
      let res;
      let img;
      
          img = axios.get(`${process.env.REACT_APP_BACK_URL_IMG}user/${user && user._id + '.png'}`)

          await img.then(res => {
            if(res.status === 200) {
                this.setState({imgValide : true})
            }
          })
          .catch(error => {
              // console.log("error 404")
          })
    }

    componentDidMount() {
      document.addEventListener("click", (e: any) => this.menuCollapsing(e))
      this.entity();
      this.userInfo();
      this.checkImageValide()
      this.props.setMenuCollapse(false)
      // !User.getStorageItem("lang") && this.props.setTranslate("fr")
    }
    

    componentWillUnmount() {
      document.removeEventListener("click", (e: any) => this.menuCollapsing(e))
    }

    logout = () => {
      User.removeToken()
      this.props.history.push("/auth")
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
      if (prevProps.collapse !== this.props.collapse) {
          this.props.setCollapse(this.props.collapse)
      }  
  }

  async entity() {
      const entitiesUser = await Socket.readLean("EntityUser", { user_uuid: this.state.userInfo && this.state.userInfo._id }, {});
      let entities = [];
      for (const entityUser of entitiesUser.data) {
          const res = await Socket.readLean("Entity", { _id: entityUser.entity_uuid }, {});
          if (res.data.length > 0) {
              entities.push(res.data[0]);
              if (entities.length === entitiesUser.data.length) {
                  this.setEntitiesOnReducer(
                      entities[this.checkProfile(entities)]
                  )
              }
          }
      }
  }

  checkProfile(entities: any) {
      const profiles = ["super_admin", "revendeur", "client"]
      let profileIndex
      for (let i = 0; i < profiles.length; i++) {
          let p = profiles[i]
          if (entities.find((e: any) => e.profile === p)) {
              profileIndex = entities.findIndex((e: any) => e.profile === p)
              break
          }
      }
      return profileIndex;
  }

  setEntitiesOnReducer = (selectedEntity : any) => {
      Socket.readTest(selectedEntity).then((res) => {
          if (res.length > 0) {
              // this.props.setEntity(res)
              this.props.setEntityId(res[0]._id)
              // this.props.udpateCollection("Entity", res)
          }
      })
  }

  // handleChange(value) {
  //     this.props.setEntityId(value)
  // }

    render() {

      return (
        <div id="navbar-top">
            <div className="menu-navbar-top">
                    <div
                        className="profile-menu"
                        //@ts-ignore
                        onClick={() => this.setState(state => ({ collapse: !state.collapse}))}
                    >
                        <img
                            key={this.props.udpateuser}
                            src={
                              this.state.imgValide === true
                                  ?
                                  `${process.env.REACT_APP_BACK_URL_IMG}user/${this.state.userInfo && this.state.userInfo._id + '.png'}?${global.Date.now()}`
                                  : AvatarProfile
                          }
                            className="mx-auto"
                            alt="profile pict for navbar"
                        />
                    </div>


                    <div
                        id="navbar-img-profile"
                        data-test="dropdown"
                        // className={this.state.theme ? "" : "dark contain-select"}
                    >
                        {/* {this.state.fileTranslate.map((element, i) => ( */}
                            <div
                                id="menu-drawer-nav"
                                className={this.state.collapse ? "menu-visible " : "menu-hidden"}
                                data-testid={this.state.collapse ? "menu-visible" : "menu-hidden"}
                                // key={i}
                            >
                                <NavLink to="/auth/home">
                                  <div style={{display: "flex", alignItems: "baseline"}}>
                                  <FontAwesomeIcon icon={faHome} />
                                  <p className="ml-2">Accueil</p>
                                  </div>
                                </NavLink>
                                <NavLink to="/auth/compte">
                                    <div style={{display: "flex", alignItems: "baseline"}}>
                                      <FontAwesomeIcon icon={faUser} />
                                      <p className="ml-2">Mon compte</p>
                                    </div>
                                </NavLink>
                                {this.state.userInfo &&
                                  this.state.userInfo.profile === "569" || this.state.userInfo.profile === "super_admin" || this.state.userInfo.profile === "Administrateur" &&
                                  <NavLink to="/auth/gestion-utilisateurs">
                                    <div style={{display: "flex", alignItems: "baseline"}}>
                                      <FontAwesomeIcon icon={faUserFriends} />
                                      <p className="ml-2">Gestion utilisateurs</p>
                                    </div>
                                  </NavLink>
                                }
                                <NavLink to="/auth" id="logout--button" onClick={this.logout}>
                                  <div style={{display: "flex", alignItems: "baseline", color: "red"}}>
                                    <FontAwesomeIcon icon={faPowerOff} />
                                    <p className="ml-2" style={{color: "red"}}>Déconnexion</p>
                                  </div>
                                </NavLink>
                            </div>
                        {/* // ))} */}
                    </div>
                </div>
        </div>
      )
    }
  
}
export default connector(withRouter((props: any) => <Navbar {...props} />))