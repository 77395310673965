const initialState = {
    visibleModalFireWall: "",
    visibleModalFiltrageWeb: "",
    modalType:"",
    modalTypeFiltrageWeb:"",
    updateFireWall: [],
    updateFiltrageWeb: [],
    equipments:[],
    categories: [],
    modalRegle:"",
    rules: [],
    listWhite: [],
    listBlack: [],
}

const SecuriteReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case "VISIBLE_MODAL_FIREWALL":
            newState = {
                ...state,
                visibleModalFireWall: action.value
            }
            return newState;
        case "VISIBLE_MODAL_FILTRAGE_WEB":
            newState = {
                ...state,
                visibleModalFiltrageWeb: action.value
            }
            return newState;
        case "MODAL_TYPE":
            newState = {
                ...state,
                modalType: action.value
            }
            return newState;
        case "MODAL_TYPE_FILTRAGE_WEB":
            newState = {
                ...state,
                modalTypeFiltrageWeb: action.value
            }
            return newState;
        case "UPDATE_FIREWALL":
            newState = {
                ...state,
                updateFireWall: action.value
            }
            return newState;    
        case "UPDATE_FILTRAGE_WEB":
            newState = {
                ...state,
                updateFiltrageWeb: action.value
            }
            return newState;            
        case "SET_EQUIPMENTS":
            newState = {
                ...state,
                equipments: action.value
            }
            return newState;     
        case "SET_CATEGORIES":
            newState = {
                ...state,
                categories: action.value
            }
            return newState;        
        case "OPEN_MODAL":
            newState = {
                ...state,
                modalRegle: action.value
            }
            return newState;          
        case "GET_RULES":
            newState = {
                ...state,
                rules: action.value
            }
            return newState;       
        case "SET_LIST_WHITE":
            newState = {
                ...state,
                listWhite: action.value
            }
            return newState;                
            return newState;       
        case "SET_LIST_BLACK":
            newState = {
                ...state,
                listBlack: action.value
            }
            
            return newState;                
        default:
            return state;
    }
}

export default SecuriteReducer;