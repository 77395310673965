import React from 'react'
import { RouteComponentProps } from 'react-router';
import {isExpired} from "react-jwt";
import {withRouter} from 'react-router';
import Socket from '../../Utils/Socket/Socket';
import './PasswordForm.scss';


interface Props {
  
}

interface State {
    password: string,
    confirmPassword: string,
    success: boolean,
    error: boolean,
}

class PasswordForm extends React.Component<Props & RouteComponentProps<any>, State> {
    private params: any;

    constructor(props: any) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            success: false,
            error: false
        }
    }

    componentDidMount() {
        if (isExpired(this.props.match.params) === true) {
            // this.props.history.push('/');
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target as HTMLInputElement;
        this.setState({password: target.value})
    }

    handleChangeconfirmPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target as HTMLInputElement;
        this.setState({confirmPassword: target.value})
    }

    handleSubmit = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.preventDefault()
        if (this.state.password !== "" && this.state.confirmPassword !== "" && this.state.password === this.state.confirmPassword) {
            Socket.resetPasswordWithoutToken(this.props.match.params.email, this.state.confirmPassword).then((res) => {
                this.setState({success: true})
                setTimeout(() => {
                    this.props.history.push('/');
                }, 3000)
            }).catch((err) => {
                this.setState({error: true})
            })
        }
    }

    render() {
        const url = window.location.pathname.substring(6, 11)
        return (
            <div className="rstpwd">
                        <form action="" className="ctn_reset_form">
                            <h1>{url === 'reset' ? "Réinitialiser votre mot de passe" : "Création de votre mot de passe"}</h1>
                            <div className={"ctn_input_text"}>
                                <label>Entrez votre nouveau mot de passe</label>
                                <input type="password" name="resetPwd" id="passwordReset" onChange={(e) => this.handleChange(e)}/>
                            </div>
                            <div className={"ctn_input_text"}>
                                <label>Confirmez votre nouveau mot de passe</label>
                                <input type="password" name="resetPwd" id="passwordReset"
                                       onChange={(e) => this.handleChangeconfirmPassword(e)}/>
                            </div>
                            <div className={"ctn_btn"}>
                                <button id="sendReset"
                                        onClick={(e: any) => this.handleSubmit(e)}>Confirmer</button>
                                <button onClick={() => this.props.history.push("/")}>Annuler</button>
                            </div>
                            <br/>
                            <br/>
                            {this.state.success === true &&
                            <h5 className="success">Mot de passe modifié</h5>
                            }
                            {this.state.error === true &&
                            <h5 className="error">Vérifier que les mots de passe correspondent</h5>
                            }
                        </form>
            </div>
        )
    }
}

export default withRouter(PasswordForm);