import React from "react";
import {connect} from "react-redux";
import './ModalForm.scss';
import AvatarProfile from "../../assets/images/Layout/profil.png";
import axios from 'axios';
import {Select, Input, Button, Modal, message} from "antd";
import SelectMultiple from "../SelectMultiple/SelectMultiple";
import TextField from "../TextField/TextField";
import SelectSimple from "../SelectSimple/SelectSimple";
import Socket, {socket} from '../../Utils/Socket/Socket';
import User from "../../Utils/User/User";

const {Option} = Select;
const outils = [{name: 'Spirit'}, {name: 'Wanup'}, {name: 'Athena'}, {name: 'R-fiber'}, {name: 'Sophia'}]


class ModalForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closeModal: false,
            utils: this.props.modal ? this.props.page[0].service : {
                Spirit: false,
                wanup: false,
                Athena: false,
                Sophia: false
            },
            arrayInfo: [],
            types: '',
            write: [],
            read: '',
            access: this.props.modal ? this.props.page[0].permission_wanup : [],
            userInfo: [],
            entity: '',
            test: '',
            data: '',
            autorisations: [],
            Writepages: '',
            Readpages: '',
            imgValide: false,
            roles: {
                Dashboard: {
                    read: true,
                    write: true,
                },
                Equipements: {
                    read: false,
                    write: false,
                },
                Notifications: {
                    read: true,
                    write: true,
                },
                Rapport: {
                    read: true,
                    write: true,
                },
                Labo: {
                    read: false,
                    write: false,
                },
                Securite: {
                    read: false,
                    write: false,
                },
                AccesInternet: {
                    read: true,
                    write: true,
                },
            }
        }
    }

    reset() {
        this.setState({
                roles: {
                    Dashboard: {
                        read: true,
                        write: true,
                    },
                    Equipements: {
                        read: false,
                        write: false,
                    },
                    Notifications: {
                        read: true,
                        write: true,
                    },
                    Rapport: {
                        read: true,
                        write: true,
                    },
                    Labo: {
                        read: false,
                        write: false,
                    },
                    Securite: {
                        read: false,
                        write: false,
                    },
                    AccesInternet: {
                        read: true,
                        write: true,
                    },
                }
            },
            {
                utils: {
                    Spirit: false, wanup: false, Athena: false, Sophia: false
                }
            });
    }


    componentDidMount() {
        this.userInfo();
        // this.getAdmin(this.props.idEntity)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.Writepages !== this.state.Writepages) {
            this.setState({Writepages: this.state.Writepages})
        }
        if (prevProps.page !== this.props.page) {
            if (this.props.page.length === 3) {
                this.setState({Writepages: this.props.page, Readpages: this.props.page})
            } else {
                this.setState({Writepages: this.props.page[0], Readpages: this.props.page[1]})
            }
        }
        if (prevProps.roleUsers !== this.props.roleUsers) {
            if (this.props.roleUsers[0] !== undefined) {
                this.setState({data: this.props.roleUsers[0], arrayInfo: this.props.modal.data})
            } else {
                this.setState({data: this.state.roles})
            }
        }
        if (prevProps.modal.typeModal !== this.props.modal.typeModal) {
            this.setState({read: [], write: []})
        }
    }

    checkImageValide = () => {
        let img;
        img = axios.get(`${process.env.REACT_APP_BACK_URL_IMG}user/${this.props.data && this.props.data._id + '.png'}`)
    }

    userInfo = async () => {
        let user = await User.userInfo();
        this.setState({userInfo: user});
        Socket.read('Entity', {pipe_drive_org_id: user.pipe_drive_org_id})
            .then(res => {
                this.setState({entity: res.data[0]})
            })
            .catch(error => console.error(error));
    }

    onChangeSelect = (e) => {
        this.setState({types: e})
    }

    getAdmin = async (id) => {
        const id_admin = await Socket.read("EntityUser", {entity_uuid: id})
        return id_admin.data[0].user_uuid
    }

    createUsers = async () => {
        const {modal, idEntity} = this.props
        const {arrayInfo, types, userInfo, entity, roles, utils} = this.state
        const {type, data} = modal
        const {firstname, lastname, email, mobile} = arrayInfo
        const autorisations = {permission_wanup: [this.state.data], service: utils};
        const AllUsers = await Socket.read("User", {email: email});

        if (AllUsers.data.length !== 0) message.warning('Utilisateur déjà existant');
        else {
            const id = await this.getAdmin(idEntity)
            const userInfo = await User.userInfo();
            if(userInfo && userInfo._id) {
                Socket.insert("Roles", null, autorisations)
                    .then(response => {
                        const infoUser = {
                            parent_id: userInfo.EntityUser[0].entity_uuid === "b6c0811f-32ce-44be-beba-50398490d95d"  ? null : userInfo._id,
                            firstname,
                            lastname,
                            email,
                            mobile,
                            profile: types,
                            role_id: response.data._id,
                            enabled: true
                        }

                        Socket.insert("User", null, infoUser)
                            .then(response => {
                                const entityUsers = {
                                    entity_uuid: idEntity ? idEntity : entity._id,
                                    user_uuid: response.data._id
                                }
                                this.setState({utils: []})

                                Socket.insert("EntityUser", null, entityUsers)
                                    .then(response => {
                                        this.props.handleOk()
                                        message.info('Utilisateur crée');
                                    })
                                    .catch(error => {
                                        message.error('Problème lors de la création');
                                    })
                            })
                    })
                    .catch(error => console.error(error))
            } else {
                console.error("Info user not found")
            }
        }
    }

    updateUsers = () => {
        const {modal} = this.props
        const {arrayInfo, types, utils} = this.state
        const {data} = modal
        const {firstname, lastname, email, mobile} = arrayInfo
        const users = {firstname, lastname, email, mobile, profile: data ? data.profile : types}
        const datas = {permission_wanup: [this.state.data], service: utils};

        Socket.read("User", { email: email })
            .then(res => {
                Socket.update("User", data._id, null, users)
                    .then(res => {

                        Socket.update("Roles", data.role_id, null, datas)
                            .then(res => {
                                this.props.handleOk()
                            })
                            .catch(error => console.error(error))
                    })
                    .catch(error => console.error(error))
            })
            .catch(error => console.error(error))
    }

    usersAction = () => {
        if (this.props.modal.type === "add") this.createUsers();
        else this.updateUsers()
    }

    onSelectWrite = (field) => {
        let newState = [...this.state.Writepages]
        newState = [
            ...newState,
            {value: field, disabled: false}
        ]
        this.setState({Writepages: newState})
        this.setState((state) => ({
            data: {...state.data, [field]: {read: true, write: true}},
        }));
    }

    onDeselectWrite = (field) => {
        const filter = this.state.Writepages.filter(e => e.value !== field)
        this.setState({Writepages: filter})
        this.setState((state) => ({
            data: {...state.data, [field]: {read: false, write: false}},
        }));
    }

    onSelectRead = (field) => {
        let newState = [...this.state.Readpages]
        newState = [
            ...newState,
            {value: field, disabled: false}
        ]
        this.setState({Readpages: newState})

        this.setState((state) => ({
            data: {...state.data, [field]: {read: true, write: this.state.data[field].write}},
        }));
    }

    onDeselectRead = (field) => {
        const filter = this.state.Readpages.filter(e => e.value !== field)
        this.setState({Readpages: filter, Writepages: filter})
        this.setState((state) => ({
            data: {...state.data, [field]: {read: false, write: this.state.data[field].read === false && false}},
        }));
    }
    onDeselect = (field) => {
        const filter = this.state.autorisations.filter(e => e !== field)
        this.setState({autorisations: filter})
    }

    onSelect = (field) => {
        let newState = [...this.state.autorisations]
        newState = [
            ...newState,
            field
        ]
        this.setState({autorisations: newState})
    }


    handleChange = (name, value) => {
        this.setState((state) => ({
            arrayInfo: {...state.arrayInfo, [name]: value},
        }));
    }

    render() {
        const {modal, page, imgValide, autorisation, idEntity} = this.props
        const {data, type} = modal
        const options = [{value: 'Dashboard'}, {value: 'Equipements'}, {value: 'Notifications'}, {value: 'Rapport'}, {value: 'Labo'}, {value: 'Securite'}, {value: 'AccesInternet'}]
        const image = data && imgValide === true ? `${process.env.REACT_APP_BACK_URL_IMG}user/${data && data._id + '.png'}?${global.Date.now()}` : AvatarProfile
        const title = modal && type === "add" ? <span>Création de l'utilisateur</span> :
            <span>Modifier l'utilisateur</span>
        return (
            <Modal
                title={title}
                visible={this.props.actionModal}
                destroyOnClose={true}
                maskClosable={false}
                onCancel={this.props.handleCancel}
                footer={[
                    <Button key="back" className={'button-confirm'} onClick={this.usersAction}>
                        {title}
                    </Button>,
                    <Button key="backy" className={'button-cancel'} onClick={this.props.handleOk}>
                        Annuler
                    </Button>
                ]}
            >
                <div style={{display: "flex", justifyContent: "center"}}>
                    <p>{title}</p>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div className="action-utilisateurs mb-5">
                        <img
                            src={image}
                            className="mx-auto"
                            alt="profile pict for navbar"
                        />
                    </div>
                </div>
                <p style={{fontWeight: 'bold'}}>Informations utilisateur</p>
                <TextField
                    placeholder="Prénom"
                    value={data && data.firstname}
                    name={'firstname'}
                    changeValue={this.handleChange}
                    type={'text'}
                    ariaLabel={`Prénoms`}
                    inputProps={{"data-testid": "content-input"}}
                    id="content"
                />
                <TextField
                    placeholder="Nom"
                    value={data && data.lastname}
                    name={'lastname'}
                    changeValue={this.handleChange}
                    type={'text'}
                    ariaLabel={`lastname`}
                    inputProps={{"data-testid": "content-input"}}
                    id="content"
                />
                <TextField
                    placeholder="Adresse mail"
                    value={data && data.email}
                    name={'email'}
                    changeValue={this.handleChange}
                    type={'email'}
                    ariaLabel={`Email`}
                    inputProps={{"data-testid": "content-input"}}
                    id="content"
                />
                <TextField
                    placeholder="Téléphone"
                    value={data && data.mobile}
                    name={'mobile'}
                    changeValue={this.handleChange}
                    type={'mobile'}
                    ariaLabel={`Phone`}
                    inputProps={{"data-testid": "content-input"}}
                    id="content"
                />
                <SelectSimple
                    items={['Développeur', 'Technicien', 'Administrateur']}
                    value={data && data.profile}
                    placeholder={'Sélectionnez un profile'}
                    changeValue={(field) => this.onChangeSelect(field)}
                    inputProps={{"data-testid": "content-input"}}
                    ariaLabel={`profile`}
                />

                <div className="mt-3">
                    <p style={{fontWeight: 'bold'}}>Autorisations</p>
                    <SelectMultiple
                        className="col-12 mb-2"
                        mode="multiple"
                        items={outils ? outils : []}
                        valuesName={'name'}
                        valuesDisplay={'name'}
                        value={this.state.autorisations}
                        placeholder="Outils"
                        Deselect={(field) => this.onDeselect(field)}
                        Select={(field) => this.onSelect(field)}
                        inputProps={{"data-testid": "content-input"}}
                        id="content"
                        ariaLabel={`autorisation`}
                    />
                </div>
                <div className="mt-3">
                    <p style={{fontWeight: 'bold'}}>Accès Wanup</p>
                    <div className={'select-roles'}>
                        {/* {this.state.Writepages &&
                        this.state.Writepages.length > 0  && this.state.Readpages.length > 0 && */}
                        <div className="access">
                            <div>
                                <p>lecture :</p>
                                <SelectMultiple
                                    items={options ? options : []}
                                    valuesName={'value'}
                                    valuesDisplay={'value'}
                                    placeholder={'page lecture'}
                                    value={this.state.Readpages && this.state.Readpages.map(e => e.value)}
                                    Deselect={(field) => this.onDeselectRead(field)}
                                    Select={(field) => this.onSelectRead(field)}
                                    inputProps={{"data-testid": "content-input"}}
                                    id="content"
                                    className="select-role mb-2"
                                    ariaLabel={`lecture`}
                                />
                            </div>
                            <div>
                                <p>écriture :</p>
                                <SelectMultiple
                                    items={options ? options : []}
                                    valuesName={'value'}
                                    valuesDisplay={'value'}
                                    placeholder={'page écriture'}
                                    value={this.state.Writepages && this.state.Writepages.map(e => e.value)}
                                    Deselect={(field) => this.onDeselectWrite(field)}
                                    Select={(field) => this.onSelectWrite(field)}
                                    inputProps={{"data-testid": "content-input"}}
                                    id="content"
                                    className="select-role mb-2 ml-2"
                                    ariaLabel={`ecriture`}
                                />
                            </div>
                        </div>
                        {/* }     */}
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.NavbarReducer.user,
        entityId: state.NavbarReducer.entity_id,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToprops)(ModalForm);
