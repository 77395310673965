const FR = [
    {
        "dashboard": "Tableau de Bord",
        "connected": "connectés",
        "bandWidth": "Bande passante",
        "trafficClassification": "Classement trafic",
        "security":"Sécurité",
        "operators": "Operateurs",
        "technology": "Technologies",
        "language": "fr",
        "home": "Accueil",
        "dayMode": "Mode jour",
        "deconnection": "Deconnexion",
        "devices": "Équipements",
        "notifications": "Notifications",
        "reporting": "Rapport",
        "building": "Labo",
        "internetAccess": "Accès internet",
        "bills": "Factures",
        "settings": "Paramètres",
        "list": "Liste",
        "map": "Carte",
        "reseller": "Revendeur", 
        "Client": "Client",
        "siteName": "Nom du site",
        "serialnum": "N°Serie",
        "state": "État",
        "nbOfActivePorts": "Nb ports actifs ",
        "model": "Modèle",
        "template": "Modèle",
        "search": "Recherche",
        "address": "Adresse",
        "addTemplate": "Ajouter un modèle",
        "disk": "Disque",
        "details": "Détails",
        "deviceCharges": "Charge équipement",
        "ipGroup": "Groupe IP",
        "Group": "Groupe",
        "addIpAdress": "Ajouter une adresse IP",
        "addIpGroup": "Ajouter un groupe IP",
        "portGroup": "Groupe PORT",
        "networkorIpAdress": "Réseau ou adresse IP",
        "addPorts": "Ajouter un port",
        "debit": "Debit",
        "ipAdress": "Adresse IP",
        "relay": "Relais",
        "filtering": "Filtrage",
        "addFiltering": "Ajouter filtrage",
        "interface": "Interface",
        "AddVirtualInterface": "Ajouter une interface virtuelle",
        "gateway": "Passerelles",
        "addGateway": "Ajouter Passerelle",
        "name": "Nom",
        "ipAdressTarget": "Adresse IP cible",
        "latency": "Latence",
        "lossPacket": "Perte de paquets",
        "firewall": "Pare-feu",
        "entry": "entrée",
        "name_adress": "Nom / adresse IP",
        "AddDomain": "Ajouter un Domaine",
        "ipStart": "Ip début",
        "ipEnd": "Ip fin",
        "leaseTime" : "Durée du bail",
        "domainName": "Nom de domaine",
        "staticIp": "Ip statique",
        "macAdress": "Adresse mac / adresse IP",
        "addIpStatic": "Ajouter IP statique",
        "optionID": "Option ID / valeur",
        "addOption": "Ajouter une option",
        "ipRelay": "IP relais",
        "output": "sortie",
        "protocol": "protocole",
        "IpSourceAdressPorts": "Adresse IP source / Ports",
        "accept": "accepter",
        "reject": "Rejeter",
        "block": "Bloquer",
        "dscpModifications": "Modifications DSCP",
        "Modifications_8021P": "Modifications 802.1P",
        "rules": "Règles",
        "comment": "commentaire",
        "addRule": "Ajouter une règle",
        "userslist": "Liste utilitsateurs",
        "categoriesAllowed": "Catégories autorisées",
        "whiteList": "Liste blanche",
        "blackList": "Liste noire",
        "addUrl": "Ajouter un URL",
        "reservation": "Réservation",
        "destinationIp": "Ip de déstination",
        "SourceIp": "Ip source",
        "AddCos": "Ajouter une COS",
        "routing": "Routage",
        "ipSourceAddress": "Adresse Ip source",
        "destination": "Déstination",
        "selfgoverning": "Autonome",
        "addRoutage": "Ajouter Routage",
        "confirm": "Confirmer",
        "cancel": "Annuler",
        "createIpsec": "Créer un Ipsec",
        "updateIpsec": "Modifier un Ipsec",
        "update": "Mettre à jour",
        "disconnected": "déconnecté",
        "inProgress": "En cours",
        "all": "Tous",
        "history": "Historique",
        "jitter": "Gigue",
        "classOfService": "Classe de services",
        "consumption4g": "Conso 4G",
        "previous": "Précédent",
        "next": "Suivant",
        "traffic": "Trafic",
        "column": "Colonne",
        "remoteIpAdress": "Adresse Ip distante",
        "remoteId": "Identifiant distant",
        "localIpAdress": "Adresse ip local",
        "localId": "Identifiant local",
        "encryptionAlgorithm": "Algorithme d'encryption",
        "hashAlgorithm": "Algorithme de Hash",
        "dhGroup": "Group DH",
        "localNetwork": "Réseau local",
        "addLocalNetwork": "Ajouter réseau local",
        "remoteNetwork": "Réseau distant",
        "addRemoteNetwork": "Ajouter réseau distant",
        "AddIpsec": "Ajouter Ipsec",
        "reference": "Référence",
        "account": "Compte",
        "users": "Utilisateurs",
        "entity": "Entité",
        "firstname": "Prénom",
        "phone": "Tel",
        "role": "Rôle",
        "administrator": "Administrateur",
        "superAdministrator": "Super administateur",
        "beginDate": "Date début",
        "endDate": "Date fin",
        "noRecords": "Pas de résultats",
        "categories": "Categories",
        "remoteVpn": "Nomade",
        "accessibleNetwork": "Réseaux accessibles",
        "addRemoteVpn": "Ajouter un nomade",
        "result": "résultats",
        "filteredResult": "résultats filtrés",
        "register": "Enregistrer",
        "boitierDescription" : "SND : Boitiers installés sur les sites des clients...",
        "DashboardCustom": "Personnalisez votre dashboard",
        "degraded": "dégradé",
        "addSite": "Ajouter un site",
        "move": "déplacer",
        "addTranslation": "Ajouter translation",
        "archive": "Archive",
        "color": "Couleur",
        "PortConfirmation": "Voulez-vous modifier le port",
        "addGroup": "Ajouter un groupe",
        "marketPlace": "Market place",
        "help":"Aide",
        "day": "Jour",
        "week": "Semaine",
        "month": "Mois",
        "thesdwan": "Le SD-WAN est",
        "operationnal": "opérationnel à",
        "flatRate": "Forfait",
        "inventoryManagement": "Gestion du stock",
        "road": "Route",
        "roadEntry": "Route entrée",
        "roadOutput": "Route sortie",
        "portEntry": "Interface entrée",
        "portOutput": "Interface sortie"
    }
]

export { FR };