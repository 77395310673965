const initialState = {
    gateways_props: [],
    gateways_delete: [],
    routage_props: [],
    modelName: [],
    compteurs: 0,
    dragAndDrop: []
}

const LaboReducer = (state = { initialState }, action) => {
    let newState;
    switch (action.type) {
        case "GATEWAYS_PROPS":
            newState = {
                ...state,
                gateways_props: action.value
            }
            return newState;
        case "GATEWAYS_DELETE":
            newState = {
                ...state,
                gateways_delete: action.value
            }
            return newState;
        case "ROUTAGE_PROPS":
            newState = {
                ...state,
                routage_props: action.value
            }
            return newState;
        case "MODEL_NAME":
            newState = {
                ...state,
                modelName: action.value
            }
            return newState;
        case "DRAG_AND_DROP":
            newState = {
                ...state,
                dragAndDrop: action.value
            }
            return newState;
        case "COMPTEUR":
            newState = {
                ...state,
                compteurs: action.value
            }
            return newState;
        default:
            return state;
    }
}

export default LaboReducer;